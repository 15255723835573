import { config, server } from "..";

export const flagProd = async ({
  productId,
  reason,
}: {
  productId: string;
  reason: string;
}) => {
  return await server.post("/flag", { productId, reason }, config);
};
