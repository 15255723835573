export interface HomePageItem {
  type: "look" | "youWillLike";
  id: number;
  title: string;
  description: string;
  price: number;
  image: string[];
}

export enum ProductStatus {
  //eslint-disable-next-line
  active = "active",
  //eslint-disable-next-line
  needs_fiat_tax = "needs_fiat_tax",
  //eslint-disable-next-line
  deactivated = "deactivated",
}

export interface IProduct {
  images: string[];
  id: string;
  description: string;
  name: string;
  price?: number;
  price_pi?: number;
  price_fiat?: number;
  condition: "used" | "new";
  userId: string;
  status?: ProductStatus;
  coordinates: [number, number];
  fiatCurrency?: string;
  paymentMode?: "pi" | "fiat" | "pi_fiat";
}
