import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
//import { APIProducts, getProductById } from "../../api/products";
import {
  deleteChat,
  deleteChatFromProdId,
  getChatInfo,
  getMessagesForChat,
  getMessagesForProductId,
  sendMessage,
  sendMessageToProduct,
} from "../../api/messaging";

import "./ChatPage.scss";
import { Button, Input, Modal, Spin } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { store } from "../../store/index.store";
import { getImageURL } from "../../common/utils/imageURLgetter";
import { ChatList } from "./ChatList";
import { useTranslation } from "react-i18next";

export enum ChatMode {
  //eslint-disable-next-line no-unused-vars
  CHAT_ID,
  //eslint-disable-next-line no-unused-vars
  PRODUCT_ID,
}

let chatChunkSize = 30;

export const ChatPage = observer(() => {
  //const [product, setProduct] = useState<APIProducts>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [message, setMessage] = useState<string>("");

  const [messages, setMessages] = useState<any[]>([]);

  const { t } = useTranslation();

  const [isLoadingChat, setIsLoadingChat] = useState(false);

  const [extrainfo, setExtraInfo] = useState<any>(null);

  let { chatId, productId } = useParams();

  let convoId = chatId;
  let chatMode: ChatMode = ChatMode.CHAT_ID;

  if (chatId === undefined && productId !== undefined) {
    convoId = productId;
    chatMode = ChatMode.PRODUCT_ID;
  }

  const navigate = useNavigate();

  const { authStore, UIStore } = store;

  useEffect(() => {
    const latestMessage =
      UIStore.localMessages[UIStore.localMessages.length - 1];

    console.log(
      latestMessage,
      latestMessage?.chatId === convoId,
      latestMessage?.productId === convoId
    );

    if (
      latestMessage?.chatId === convoId ||
      latestMessage?.productId === convoId
    ) {
      setMessages([
        {
          message: latestMessage.message,
          senderId: latestMessage.senderId,
          unvalidated: false,
          date: new Date().getTime(),
        },
        ...messages,
      ]);
    }
  }, [UIStore.localMessages]);

  const initialChatLoader = async () => {
    if (!convoId) return;

    switch (chatMode) {
      case ChatMode.CHAT_ID: {
        try {
          setIsLoadingChat(true);
          const [chatMesages, extraInfo] = await Promise.allSettled([
            //    getProductById(chatId),
            getMessagesForChat(convoId, 0, chatChunkSize),
            getChatInfo({ chatId: convoId }),
          ]);

          if (extraInfo?.status === "fulfilled")
            setExtraInfo(extraInfo?.value?.data);

          if (chatMesages?.status !== "fulfilled") {
            return;
          }

          setMessages(chatMesages.value.data.messages);
        } catch (e) {
        } finally {
          setIsLoadingChat(false);
        }
        break;
      }
      case ChatMode.PRODUCT_ID: {
        try {
          setIsLoadingChat(true);
          const [chatMesages, extraInfo] = await Promise.allSettled([
            getMessagesForProductId(convoId, 0, chatChunkSize),
            getChatInfo({ productId: convoId }),
          ]);

          if (extraInfo?.status === "fulfilled")
            setExtraInfo(extraInfo?.value?.data);

          if (chatMesages?.status !== "fulfilled") {
            return;
          }

          setMessages(chatMesages.value.data.messages);
        } catch (e) {
        } finally {
          setIsLoadingChat(false);
        }
        break;
      }
    }
  };

  const loadMoreMessages = async () => {
    if (!convoId) return;

    let chatMesages;

    setIsLoadingChat(true);

    try {
      switch (chatMode) {
        case ChatMode.CHAT_ID: {
          chatMesages = await getMessagesForChat(
            convoId,
            messages.length,
            chatChunkSize
          );
          break;
        }
        case ChatMode.PRODUCT_ID: {
          chatMesages = await getMessagesForProductId(
            convoId,
            messages.length,
            chatChunkSize
          );
          break;
        }
      }
    } catch (e) {
    } finally {
      setIsLoadingChat(false);
      //@ts-ignore
      setMessages([...messages, ...chatMesages.data.messages]);
    }
  };

  useEffect(() => {
    (async () => {
      await initialChatLoader();
    })();
  }, [chatId]);

  return (
    <Spin spinning={isLoadingChat}>
      <Modal
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onOk={async () => {
          if (!convoId) return;

          switch (chatMode) {
            case ChatMode.CHAT_ID: {
              await deleteChat(convoId);
              break;
            }
            case ChatMode.PRODUCT_ID: {
              await deleteChatFromProdId(convoId);
              break;
            }
          }

          navigate("/chats");
        }}
      >
        {t("confirm_delete")}
      </Modal>
      <div className="c-messaging">
        <Link
          className="c-messaging__info"
          to={extrainfo?.product ? `/product/${extrainfo?.product._id}` : "#"}
        >
          {extrainfo?.product && (
            <img
              src={getImageURL(extrainfo?.product.images[0], true)}
              alt="product"
              className="c-messaging__info-image"
            />
          )}
          <div className="c-messaging__info-data">
            {extrainfo?.product && (
              <Link
                to={`/product/${extrainfo?.product._id}`}
                className="c-messaging__info-data-productname"
              >
                {extrainfo?.product.title} - {extrainfo?.product.price} PI
              </Link>
            )}
            <Link
              to={
                extrainfo?.otherUserData?.userId
                  ? `/user/products/${extrainfo?.otherUserData?.userId}`
                  : "#"
              }
              className="c-messaging__info-data-username"
            >
              {t("chat_with")}{" "}
              {extrainfo?.otherUserData?.name || "deleted user"}
              {/* <div
                className={`c-messaging__info-data-status c-messaging__info-data-status--${
                  extrainfo?.otherUserData?.status ? "online" : "offline"
                }`}
              ></div> */}
            </Link>
          </div>
          <Button
            className="c-messaging__info-delete"
            onClick={(e) => {
              e.preventDefault();
              setIsDeleteModalOpen(true);
            }}
          >
            {t("delete_conv")}
          </Button>
        </Link>
        <ChatList messages={messages} loadMoreMessages={loadMoreMessages} />
        <div className="c-messaging__input">
          <Input
            placeholder={t("chat_placeholder") || ""}
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button
            className="c-messaging__input-button"
            type="primary"
            onClick={async () => {
              try {
                if (!convoId) return;

                //put the message in the list with the parameter unvalidated
                const messageVar = {
                  message: message,
                  senderId: authStore.userProfile.userId,
                  unvalidated: true,
                  date: new Date().getTime(),
                };

                setMessages([messageVar, ...messages]);

                setMessage("");

                let chatMesages;

                switch (chatMode) {
                  case ChatMode.CHAT_ID: {
                    await sendMessage(message, convoId);
                    chatMesages = await getMessagesForChat(
                      convoId,
                      0,
                      chatChunkSize
                    );
                    break;
                  }
                  case ChatMode.PRODUCT_ID: {
                    await sendMessageToProduct(message, convoId);
                    chatMesages = await getMessagesForProductId(
                      convoId,
                      0,
                      chatChunkSize
                    );
                    break;
                  }
                }

                setMessages(chatMesages.data.messages);
              } catch (e) {}
            }}
          >
            {t("send")} <ArrowRightOutlined />
          </Button>
        </div>
      </div>
    </Spin>
  );
});
