import { observer } from "mobx-react-lite";
import "./WishlistPage.scss";
import { store } from "../../store/index.store";
import { useEffect, useState } from "react";
import { APIProducts, getProductById } from "../../api/products";
import { IProduct } from "../../common/models";
import { productMapper } from "../../mappers/productMappers";
import { Product } from "../../components/product/Product";
import { Spin } from "antd";
import { PathBasedBreadcrumb } from "../../components/PathBasedBreadcrumb/PathBasedBreadcrumb";

export const WishlistPage = observer(() => {
  const { authStore, loaderStore } = store;

  const { wishlist } = authStore;
  const { isEditingWishes, isLoadingWishes } = loaderStore;
  const [loadingProducts, setIsLoadingProducts] = useState(false);

  const [products, setProducts] = useState([] as IProduct[]);

  useEffect(() => {
    try {
      setIsLoadingProducts(true);
      const productsReq = wishlist.map((item) => {
        return getProductById(item.itemId);
      });

      setProducts([]);

      Promise.allSettled(productsReq).then(
        (data: PromiseSettledResult<APIProducts>[]) => {
          data.forEach((item) => {
            if (item.status === "rejected") return;
            setProducts((prev) => {
              return [...prev, productMapper(item.value.productObj)];
            });
          });
        }
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoadingProducts(false);
    }
  }, [wishlist]);

  return (
    <div className="wishlist-page">
      <PathBasedBreadcrumb />
      <div style={{ textAlign: "center", fontSize: 20, marginTop: 20 }}>
        {" "}
        Your Wishlist
      </div>
      <Spin spinning={isLoadingWishes || isEditingWishes || loadingProducts}>
        <div className="p-category-page">
          {products.map((product, i) => {
            return (
              <Product
                key={product.id + i}
                mode="vertical"
                onAddToCart={() => {}}
                {...product}
              />
            );
          })}
        </div>
      </Spin>
    </div>
  );
});
