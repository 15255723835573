import { Product } from "../product/Product";

import "./YoullLikeList.scss";
import { store } from "../../store/index.store";
import { observer } from "mobx-react-lite";
import classNames from "classnames";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { ShoppingCartOutlined } from "@ant-design/icons";

export const YoullLikeList = observer(() => {
  const { homePageStore, loaderStore } = store;

  const { youWillLikeItems } = homePageStore;

  const { t } = useTranslation();

  return (
    <div className={classNames("c-youllike-list")}>
      <div className="c-youllike-list__header">
        <h3 className="c-youllike-list__header-title">
          {t("home-page.youLikeIt")}
        </h3>
        {/* 
        <Link to="/more-like-this" className="c-youllike-list__header-link">
          More like this
        </Link> */}
      </div>
      <div
        className={classNames("c-youllike-list__products", {
          // "c-youllike-list__products--nolist": UIStore?.isUserOverlayOpen,
        })}
      >
        {loaderStore?.isLoadingYouWillLike ? (
          <div>
            <Spin spinning={true} />
          </div>
        ) : youWillLikeItems.length > 0 ? (
          youWillLikeItems.map((product) => {
            return (
              <Product
                mode="horizontal"
                onAddToCart={() => {}}
                {...product}
                key={product.id}
              />
            );
          })
        ) : (
          <>
            <div className="p-search__items__container-empty">
              <ShoppingCartOutlined className="p-search__items__container-empty-icon" />
              <div>No products found in your area</div>
            </div>
          </>
        )}
      </div>
    </div>
  );
});
