import { TakeALook } from "components/TakeALook/TakeALook";
import { YoullLikeList } from "components/YoullLikeList/YoullLikeList";
import { observer } from "mobx-react-lite";
import { store } from "store/index.store";

import { Carrousel } from "./Carrousel/Carrousel";

import "./HomePage.scss";
import { useEffect } from "react";
import { Alert } from "antd";

export const HomePage = observer(() => {
  const { homePageStore, authStore } = store;

  const { fetchYouWillLikeItems, fetchTakeALookItems } = homePageStore;

  useEffect(() => {
    if (authStore.isLoggedIn && !authStore?.location) return;
    fetchYouWillLikeItems(
      authStore?.location?.slice(0.2).map((el) => el.toString()) as [
        string,
        string
      ]
    );
    fetchTakeALookItems(
      authStore?.location?.slice(0.2).map((el) => el.toString()) as [
        string,
        string
      ]
    );
  }, [authStore.location, authStore.isLoggedIn]);

  return (
    <div className="c-home-page">
      {
        <Alert
          type="success"
          message={`We are open! \n Register and post your items for sale today!`}
          banner
          style={{ whiteSpace: "pre-line" }}
        />
      }

      <Carrousel />

      {(!!authStore.location || !authStore.isLoggedIn) && (
        <>
          <YoullLikeList />

          <TakeALook />
        </>
      )}
    </div>
  );
});
