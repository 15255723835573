import { Button, Space, Form, Input, notification, Spin } from "antd";
import { store } from "../../store/index.store";
import "./UserProfile.scss";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import editButton from "../../assets/images/editButton.png";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { ProfileImage } from "../UploadableProfileImage/ProfileImage";
import { dateDeltaTextGen } from "../../common/utils/dateDeltaTextGen";
import { changePassword, sendOtp } from "../../api/authorisation";
import { PathBasedBreadcrumb } from "../PathBasedBreadcrumb/PathBasedBreadcrumb";
import { updateProfile, updateWalletAdress } from "../../api/profile";
import { toast } from "react-toastify";

export const UserProfile = observer(() => {
  const { authStore } = store;
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const [submitedOtp, setSubmitedOtp] = useState(false);

  const [newWalletAddress, setNewWalletAddress] = useState("");
  const [isLoadingAddr, setIsLoadingAddr] = useState(false);
  const [isMailPreference, setIsMailPreference] = useState(false);

  const saveChanges = async () => {
    if (!submitedOtp) {
      if (form.getFieldValue("email") !== authStore.userProfile.email) {
        notification.error({
          message: "Error",
          description: "Email doesnt match",
        });

        return;
      }

      await sendOtp({ email: form.getFieldValue("email") });

      setSubmitedOtp(true);
    } else {
      if (form.getFieldValue("email") !== authStore.userProfile.email) {
        notification.error({
          message: "Error",
          description: "Email doesnt match",
        });

        return;
      }

      if (
        form.getFieldValue("password") !==
        form.getFieldValue("password-confirm")
      ) {
        notification.error({
          message: "Error",
          description: t("pass_err"),
        });

        return;
      }

      if (!form.getFieldValue("otp")) {
        notification.error({
          message: "Error",
          description: t("enter_otp_err"),
        });

        return;
      }

      await changePassword({
        email: form.getFieldValue("email"),
        otp: form.getFieldValue("otp"),
        password: form.getFieldValue("password"),
      });

      localStorage.clear();

      window.location.pathname = "/login";

      setOpen(true);
    }
  };

  const rex = new RegExp("^G[2-7A-Z]{55}$");

  return (
    <div
      className="c-profile-setting"
      style={{
        display: "contents",

        padding: "10px",
        width: "50%",
        margin: 10,
      }}
    >
      <PathBasedBreadcrumb />

      <ProfileImage />

      <div className="c-profile-setting__username">
        {authStore.userProfile.fullName || "Loading..."}
      </div>
      <div className="c-profile-setting__userjointime">
        {dateDeltaTextGen(
          new Date().getTime(),
          authStore.userProfile.createdAt
        )}
      </div>
      <div className="c-profile-setting__form-holder">
        <Form
          form={form}
          name="dynamic_form_nest_item"
          autoComplete="off"
          layout="vertical"
          className="c-profile-setting__form"
        >
          <label className="c-profile-setting__profile_label">
            {t("edit_profile")}
          </label>
          <Form.Item style={{ width: "350px" }} name="email">
            <Input
              height={50}
              className="c-profile-setting__input"
              placeholder="email@provider.com"
              suffix={
                <img
                  src={editButton}
                  alt="edit button"
                  width={"30px"}
                  height={"30px"}
                  className="c-profile-setting__editButton"
                />
              }
            />
          </Form.Item>
          <label className="c-profile-setting__email-label" htmlFor="email">
            {t("confirm_email")}
          </label>
          <label className="c-profile-setting__security">{t("security")}</label>
          <label className="c-profile-setting__profile_label">
            {t("new_password")}
          </label>
          <Form.Item style={{ width: "350px" }} name="password">
            <Input
              height={50}
              type="password"
              className="c-profile-setting__input"
              placeholder="****************"
              suffix={
                <img
                  src={editButton}
                  alt="edit button"
                  width={"30px"}
                  height={"30px"}
                  className="c-profile-setting__editButton"
                />
              }
            />
          </Form.Item>
          <label className="c-profile-setting__email-label" htmlFor="password">
            {t("new_password")}
          </label>
          <label className="c-profile-setting__profile_label">
            {t("confirm_new_password")}
          </label>
          <Form.Item style={{ width: "350px" }} name="password-confirm">
            <Input
              height={50}
              type="password"
              className="c-profile-setting__input"
              placeholder="****************"
              suffix={
                <img
                  src={editButton}
                  alt="edit button"
                  width={"30px"}
                  height={"30px"}
                  className="c-profile-setting__editButton"
                />
              }
            />
          </Form.Item>
          <label
            className="c-profile-setting__email-label"
            htmlFor="password-confirm"
          >
            {t("new_pwd_again")}
          </label>

          {submitedOtp && (
            <>
              <label className="c-profile-setting__profile_label">
                {t("enter_otp")}
              </label>
              <Form.Item style={{ width: "350px" }} name="otp">
                <Input
                  height={50}
                  className="c-profile-setting__input"
                  placeholder="Enter OTP code"
                  inputMode="numeric"
                />
              </Form.Item>
              <p
                dangerouslySetInnerHTML={{ __html: t("email_reminder") || "" }}
              ></p>
            </>
          )}
          {
            <div style={{ display: "flex" }}>
              <Button
                className="c-profile-setting__success-modal-button"
                type="primary"
                shape="round"
                onClick={saveChanges}
              >
                {t("submit_changes")}
              </Button>
            </div>
          }

          <Form.Item>
            {
              <>
                <Modal
                  open={open}
                  onCancel={() => setOpen(false)}
                  footer={[
                    <Button
                      className="c-profile-setting__success-modal-button-footer"
                      key="okay"
                      onClick={() => setOpen(false)}
                    >
                      {t("succes_oky")}
                    </Button>,
                  ]}
                  className="c-profile-setting__success-modal"
                  centered={true}
                >
                  <Space direction="vertical">
                    <label className="c-profile-setting__success-modal-header">
                      {t("congrats")}
                    </label>
                    <label className="c-profile-setting__success-modal-message">
                      {t("email_updated")}
                    </label>
                  </Space>
                </Modal>
              </>
            }
          </Form.Item>
          <div className="c-profile-setting__wallet-addr">
            <p>{t("your_pub_addr")}</p>
            <b style={{ wordWrap: "break-word" }}>
              {authStore.userProfile?.walletAddress}
            </b>
            <Spin
              spinning={isLoadingAddr}
              style={{ display: "flex !important", flexDirection: "column" }}
            >
              <div>
                <label htmlFor="newAddress">{t("new_pub_addr")}</label>
                <Input
                  id="newAddress"
                  value={newWalletAddress}
                  onChange={(e) => setNewWalletAddress(e.target.value)}
                />
                <Button
                  style={{ margin: "10px auto", borderRadius: "15px" }}
                  type="primary"
                  onClick={async () => {
                    setIsLoadingAddr(true);

                    if (!rex.test(newWalletAddress)) {
                      alert(t("invalid_wallet_adress"));
                      return setIsLoadingAddr(false);
                    }
                    try {
                      await updateWalletAdress(newWalletAddress);
                      authStore.userProfile.walletAddress = newWalletAddress;
                      toast.success(t("wallet_succes"));
                      setNewWalletAddress("");
                    } catch (e) {
                      toast.error(t("wallet_error"));
                    } finally {
                      setIsLoadingAddr(false);
                    }
                  }}
                >
                  {t("submit_new_addr")}
                </Button>
              </div>
            </Spin>
          </div>
          <div>
            {t("mail_preference", {
              status: authStore.userProfile?.mailPreference
                ? t("mail_preference_statuses.true")
                : t("mail_preference_statuses.false"),
            })}
            <br /> <br />
            <Spin spinning={isMailPreference}>
              <Button
                type="primary"
                onClick={async () => {
                  setIsMailPreference(true);

                  try {
                    await updateProfile({
                      mailPreference: !authStore.userProfile?.mailPreference,
                    });
                    authStore.setUserProfile({
                      mailPreference: !authStore.userProfile?.mailPreference,
                    });
                  } catch (e) {
                    toast.error(t("mail_preference_error"));
                  } finally {
                    setIsMailPreference(false);
                  }
                }}
              >
                {authStore.userProfile?.mailPreference
                  ? t("mail_preference_disable")
                  : t("mail_preference_enable")}
              </Button>
            </Spin>
            <br />
            <br />
            <span
              dangerouslySetInnerHTML={{
                __html: t("mail_preference_info") as string | "",
              }}
            />
          </div>
        </Form>
      </div>
    </div>
  );
});
