import {
  EditOutlined,
  DeleteOutlined,
  DollarOutlined,
} from "@ant-design/icons";
import { Carousel } from "antd";
import type { IProduct, ProductStatus } from "../../common/models";
import { getImageURL } from "../../common/utils/imageURLgetter";

import "./EditableProduct.scss";
import { Link } from "react-router-dom";

const statusMap: Record<ProductStatus, string> = {
  active: "Active",
  needs_fiat_tax: "Requires fiat listing fee",
  deactivated: "Deactivated",
};

export const EditableProduct = ({
  product,
  onDelete,
  onEdit,
  onFiatTax,
}: {
  product: IProduct;
  onDelete: () => void;
  onEdit: () => void;
  onFiatTax: () => void;
}) => {
  const oldPrice = product.price;

  const productPrice =
    !!product.price_pi && product.price_pi > 0 ? product.price_pi : oldPrice;

  const paymentContainsPi =
    product?.paymentMode === "pi" ||
    product?.paymentMode === "pi_fiat" ||
    !product?.paymentMode;

  const paymentContainsFIAT =
    product?.paymentMode === "fiat" || product?.paymentMode === "pi_fiat";

  return (
    <div className="c-editable-product">
      <Link to={`/product/${product.id}`} className="c-editable-product__link">
        <>
          <div>
            <Carousel className="c-editable-product__images" autoplay>
              {product?.images?.map((imageLink, index) => (
                <img
                  src={getImageURL(imageLink)}
                  alt={product?.name}
                  key={index}
                  className="c-product__image"
                />
              ))}
            </Carousel>
          </div>
          <div className="c-editable-product__info">
            <p className="c-editable-product__info-name">{product.name}</p>
            <p className="c-editable-product__info-description">
              {product?.description}
            </p>
            <p className="c-editable-product__info-price">
              {paymentContainsPi && `${productPrice} Pi`}
              {product.paymentMode === "pi_fiat" && " - "}
              {paymentContainsFIAT &&
                `${product.price_fiat} ${product.fiatCurrency}`}
            </p>
            {!!product?.status && (
              <span
                className={`c-editable-product__info-status c-editable-product__info-status--${product?.status}`}
              >
                <span style={{ color: "black", fontWeight: "normal" }}>
                  Listing status:
                </span>
                {statusMap[product?.status]}
                {product?.status === "needs_fiat_tax" && (
                  <DollarOutlined
                    className="c-editable-product__icons-icon"
                    onClick={onDelete}
                  />
                )}
              </span>
            )}
          </div>
        </>
      </Link>

      <div className="c-editable-product__icons">
        <EditOutlined
          className="c-editable-product__icons-icon"
          onClick={onEdit}
        />
        <DeleteOutlined
          className="c-editable-product__icons-icon"
          onClick={onDelete}
        />
        {product?.status === "needs_fiat_tax" && (
          <DollarOutlined
            className="c-editable-product__icons-icon  c-editable-product__icons-icon--fiat"
            onClick={onFiatTax}
          />
        )}
      </div>
    </div>
  );
};
