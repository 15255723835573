import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, List, Row } from "antd";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import "./StorePanelList.scss";

const data = [
  { title: "Dashboard", to: "/dashboard" },
  { title: "Add/Edit Subcategories", to: "/addEditSubCategories" },
  { title: "Add/Edit catalogue", to: "/addEditCatalogue" },
  { title: "Stock & Prices management", to: "" },
  { title: "Manage orders", to: "/manageOrders" },
  { title: "Manage offers", to: "/manageOffers" },
  { title: "Featured listings", to: "/featuredListing" },
  { title: "Billing and payment", to: "" },
  { title: "Settings", to: "" },
];

export const StorePanelList = observer(() => {
  return (
    <>
      <div className="c-store-panel_list" style={{ padding: "10px" }}>
        <Link to={"/home-page"} style={{ color: "black" }}>
          <ArrowLeftOutlined />
        </Link>
        <label> Store Panel </label>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="c-store-panel_list"
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta title={<Link to={item.to}>{item.title}</Link>} />
            <ArrowRightOutlined />
          </List.Item>
        )}
      />
      <div className="c-store-panel_list-logout">
        <Row>
          <label className="c-store-panel_list-logout-label ">Log out</label>
          <ArrowRightOutlined className="c-store-panel_list-logout-arrow" />
        </Row>
      </div>
      <div className="c-store-panel_list-question">
        <Button type="primary" shape="circle" style={{ background: "#159A8C" }}>
          ?
        </Button>
      </div>
    </>
  );
});
