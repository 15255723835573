import { Carousel } from "antd";

import "./Carrousel.scss";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { store } from "../../../store/index.store";
import { useTranslation } from "react-i18next";

export const Carrousel = observer(() => {
  const { authStore } = store;
  const { isLoggedIn } = authStore;
  const { t } = useTranslation();

  const share = async () => {
    if (window.Pi.api.accessToken) {
      window.Pi.openShareDialog(
        t("share_app"),
        t("share_app_text_pi", {
          href: window.location.href,
        })
      );
      return;
    }

    if (!navigator.share) return;
    try {
      await navigator.share({
        title: t("share_app_title") || "",
        text: t("share_app_text") || "",
        url: window.location.href,
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Carousel autoplay className="c-carrousel" autoplaySpeed={10000}>
      <div className="c-carrousel-frame">
        <div className="c-carrousel-frame__gradient">
          <div className="c-carrousel-frame__text-holder">
            <div className="c-carrousel-frame__text--title">
              {isLoggedIn ? "Post an item today!" : "Register today!"}{" "}
            </div>
            <div className="c-carrousel-frame__text--sub">
              <Link to={isLoggedIn ? "/post-product" : "/login-register"}>
                click here
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="c-carrousel-frame">
        <div className="c-carrousel-frame__gradient">
          <div className="c-carrousel-frame__text-holder">
            <div className="c-carrousel-frame__text--title">
              How it works tutorial video
            </div>
            <div className="c-carrousel-frame__text--sub">
              <a href="https://youtu.be/QDkyG8Wjjek">Click here for tutorial</a>
            </div>
          </div>
        </div>
      </div>
      <div className="c-carrousel-frame">
        <div className="c-carrousel-frame__gradient">
          <div className="c-carrousel-frame__text-holder">
            <div className="c-carrousel-frame__text--title">
              Share this app with other pioneers
            </div>
            <a
              href="/home-page    "
              className="c-carrousel-frame__text--sub"
              onClick={share}
            >
              Click Here
            </a>
          </div>
        </div>
      </div>
    </Carousel>
  );
});
