import { useEffect, useState } from "react";
import { getChats } from "../../api/messaging";

import "./ChatsPage.scss";
import { Spin } from "antd";
import { Chats } from "./Chats";
import { PathBasedBreadcrumb } from "../../components/PathBasedBreadcrumb/PathBasedBreadcrumb";

export const ChatsPage = () => {
  const [chats, setChats] = useState<any[]>([]);

  const [isLoadingChats, setIsLoadingChats] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoadingChats(true);
        const chats = await getChats(0, 10);

        setChats(chats.data.chats);
      } catch (e) {
      } finally {
        setIsLoadingChats(false);
      }
    })();
  }, []);

  const loadMore = async () => {
    try {
      setIsLoadingChats(true);
      const newChats = await getChats(chats.length, 10);

      setChats([...chats, ...newChats.data.chats]);
    } catch (e) {
    } finally {
      setIsLoadingChats(false);
    }
  };

  return (
    <div className="p-chats">
      <PathBasedBreadcrumb />
      <Spin spinning={isLoadingChats}>
        <Chats chats={chats} loadMore={loadMore} />
      </Spin>
    </div>
  );
};
