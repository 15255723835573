import { Input, Modal, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { updateWalletAdress } from "../../api/profile";
import { store } from "../../store/index.store";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const WalletAddrModal = observer(
  ({ state, setState }: { state: boolean; setState: Function }) => {
    const { authStore } = store;

    const [walletAddress, setWalletAddress] = useState("");
    const { t } = useTranslation();

    const [walletState, setWalletState] = useState(0);

    const rex = new RegExp("^G[2-7A-Z]{55}$");
    return (
      <Modal
        open={state}
        title={t("wallet_succes_modal_title")}
        onCancel={() => {
          switch (walletState) {
            case 0:
              toast.error(t("wallet_succes_modal"));
              setState(false);
              break;
            case 1:
              setWalletState(0);
              break;
          }
        }}
        onOk={async () => {
          switch (walletState) {
            case 0:
              if (!rex.test(walletAddress)) {
                alert(t("invalid_wallet_adress"));
                return;
              }
              setWalletState(1);
              break;
            case 1:
              setWalletState(2);

              try {
                await updateWalletAdress(walletAddress);
                authStore.userProfile.walletAddress = walletAddress;
              } catch (e) {
                toast.error(t("wallet_update_error"));
                setWalletState(1);
              } finally {
                setState(false);
              }
              break;
          }
        }}
      >
        <Spin spinning={walletState === 2}>
          {walletState === 0 ? (
            <>
              <p>{t("wallet_modal_1")}</p>
              <Input
                value={walletAddress}
                onChange={(e) => setWalletAddress(e.target.value)}
              />
              <p>{t("wallet_modal_2")}</p>
              <p>{t("wallet_modal_3")}</p>
              <p>{t("wallet_modal_4")}</p>
            </>
          ) : (
            <>
              <p>{t("wallet_modal_confirm")}</p>
              <p>
                <b>{walletAddress}</b>
              </p>
              <p>{t("wallet_modal_confirm_2")}</p>
            </>
          )}
        </Spin>
      </Modal>
    );
  }
);
