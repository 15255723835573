import { makeAutoObservable } from "mobx";
import { server } from "..";

class ProductStore {
  postItem: {} | any;
  loaderStore: any;

  constructor(loaderStore: any) {
    makeAutoObservable(this);
    this.loaderStore = loaderStore;
  }

  postNewItem = async (data: any) => {
    const token = localStorage.getItem("token");

    const respData = await server.post(`/products`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    this.postItem = data;

    console.log("respData", respData);

    return respData;
  };

  editItem = async (data: any) => {
    const token = localStorage.getItem("token");

    await server
      .patch(`/products/${data.id}`, data.values, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        this.postItem = data;
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
}

export { ProductStore };
