import { Alert } from "antd";
import { observer } from "mobx-react-lite";
import { store } from "../../store/index.store";

import "./VerifyAccount.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { config } from "../../index";

export const VerifyAccount = observer(() => {
  const { authStore } = store;

  const [error, setError] = useState("");

  const navigator = useNavigate();

  const { form } = authStore;

  const { email, name, password } = form;

  useEffect(() => {
    if (!email) navigator("/register");
  }, [email]);

  useEffect(() => {
    (async () => {
      if (!email) return;
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/register`,
          {
            email: email.toLowerCase(),
            fullName: name,
            password,
          },
          config
        );

        if (data.success) return navigator("/verifyOTP");
      } catch (e: any) {
        setError(e.message + ". Please try registering again.");
      }
    })();
  }, []);

  return (
    <div className="c-verify-account">
      <div className="c-verify-account__Verify">
        <label className="c-verify-account__Verify-label">Verify Account</label>
        {error && (
          <>
            <br />
            <Alert message={error} type="error" />
            <br />
          </>
        )}
        <div>
          <label className="c-verify-account__Verify-label-message">
            We have sent an email to {email.toLowerCase()}. Please check your
            email.
          </label>
        </div>
      </div>
    </div>
  );
});
