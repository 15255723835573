import { Link } from "react-router-dom";
import { getImageURL } from "../../common/utils/imageURLgetter";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { useTranslation } from "react-i18next";

interface ChatProps {
  chats: any[];
  loadMore: () => void;
}

export const Chats = ({ chats, loadMore }: ChatProps) => {
  const { t } = useTranslation();
  useBottomScrollListener(
    () => {
      loadMore();
    },
    {
      offset: 0,
      debounce: 200,
      debounceOptions: { leading: true },
      triggerOnNoScroll: false,
    }
  );

  return (
    <>
      {chats.map((chat) => (
        <Link
          to={`/chats/chat/${chat.chat._id}`}
          key={chat.chat._id}
          className="p-chats__chats-chat"
        >
          {chat.product && (
            <img
              src={getImageURL(chat.product.images[0], true)}
              alt={chat.product.title}
              className="p-chats__chats-chat-image"
            />
          )}
          <div className="p-chats__chats-chat__info">
            {chat.product && (
              <p className="p-chats__chats-chat__info-productname">
                {chat.product.title}
              </p>
            )}
            <p className="p-chats__chats-chat__info-username">
              {t("chat_with")} {chat.userName || "deleted user"}
            </p>
          </div>
        </Link>
      ))}
    </>
  );
};
