import "./faq.css";
import { useTranslation } from "react-i18next";
import { PathBasedBreadcrumb } from "../PathBasedBreadcrumb/PathBasedBreadcrumb";

export const FAQ = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="feedback">
        <div className="fb-faq">
          <div className="feed-link">
            <PathBasedBreadcrumb />
          </div>

          <div className="faq-div">
            <div className="question">
              <p className="p-question">
                {t("faq.title1")}
                {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body1")}</p>
            </div>

            <div className="question">
              <p className="p-question">
                {t("faq.title2")}
                {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body2")}</p>
            </div>

            <div className="question">
              <p className="p-question">
                {t("faq.title3")}
                {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body3")}</p>
            </div>

            <div className="question">
              <p className="p-question">
                {t("faq.title4")}
                {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body4")}</p>
            </div>

            <div className="question">
              <p className="p-question">
                {t("faq.title5")} {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body5")}</p>
            </div>

            <div className="question">
              <p className="p-question">
                {t("faq.title6")}
                {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body6")}</p>
            </div>

            <div className="question">
              <p className="p-question">
                {t("faq.title7")} <span className="plus">+</span>
              </p>
              <p>{t("faq.body7")}</p>
            </div>

            <div className="question">
              <p className="p-question">
                {t("faq.title8")} {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body8")}</p>
            </div>
            <div className="question">
              <p className="p-question">
                {t("faq.title9")} {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body9")}</p>
            </div>
            <div className="question">
              <p className="p-question">
                {t("faq.title10")} {/* <span className='plus'>+</span> */}
              </p>
              <p>{t("faq.body10")}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
