import { ArrowLeftOutlined } from "@ant-design/icons"
import { Button } from "antd";
import { Link } from "react-router-dom"

import successful from "../../assets/images/successful.png"

import "./WatizenSuccessful.scss";


export const WatizenSuccessful = () => {
    return(
        <div>
            <div className="c-watizen-successful">
                <Link to={"/watizenPanel"} style={{color:"black"}}>
                    <ArrowLeftOutlined />
                </Link>
                <label> Watizen Panel </label>
            </div>
            <div className="c-watizen-successful_image">
                <img src={successful} alt="succssful" />
            </div>
            <div className="c-watizen-successful_message">
                <label className="c-watizen-successful_message-label">Congratulation!</label>
            </div>
            <div className="c-watizen-successful_info">
                <label className="c-watizen-successful_info-message">Successfully registered as a waitizen!</label>
            </div>
            <Link to={"/watizenList"}>
                <div className="c-watizen-successful_button">
                    <Button type="primary" shape="round" className="c-watizen-successful_button-panel" >
                    Back to Watizen Panel
                    </Button>
                </div>
            </Link>
        </div>
    )
}