import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRedirect } from "../../api/redirect";
import CryptoJs from "crypto-js";

const redirectIfData = async (id: string, encKey: string, navigator: any) => {
  try {
    const redirect = await getRedirect(id);

    if (!redirect) {
      navigator("/login");
      return;
    }

    const redirectData = redirect.data;

    const decToken = CryptoJs.AES.decrypt(redirectData.token, encKey, {
      iv: CryptoJs.lib.WordArray.create([0x0, 0x0, 0x0, 0x0]),
    }).toString(CryptoJs.enc.Utf8);

    localStorage.setItem("token", decToken);

    window.location.href = decodeURIComponent(redirectData.url);
  } catch (e: any) {
    alert(e.message);
  }
};

export const TokenLogin = () => {
  const { id, encKey } = useParams();

  const navigator = useNavigate();

  useEffect(() => {
    if (!id || !encKey) {
      navigator("/login");
      return;
    }

    redirectIfData(id, encKey, navigator);
  }, [id, encKey, navigator]);

  return (
    <div>
      <h1>Logging you in , please wait</h1>
    </div>
  );
};

export const PathRedirect = () => {
  const { path } = useParams();

  useEffect(() => {
    if (!path) {
      window.location.href = "/login";
      return;
    }

    window.location.href = `pi://watugot.net/${path}`;
  }, [path]);

  return (
    <div>
      <h1>Redirecting you to the login page</h1>
    </div>
  );
};
