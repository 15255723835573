import { LoaderStore } from "./loader.store";
import { ProductPageStore } from "./productPage.store";
import { UIStore } from "./UI.store";
import { AuthStore } from "./authStore.store";
import { ProductStore } from "./ProductStore.store";
import { HomePageStore } from "./homePage.store";

class RootStore {
  UIStore;
  loaderStore;
  productPageStore;
  authStore;
  productStore;
  homePageStore;

  constructor() {
    this.loaderStore = new LoaderStore();
    this.UIStore = new UIStore(this.loaderStore);
    this.productPageStore = new ProductPageStore(this.loaderStore);
    this.authStore = new AuthStore(this.loaderStore);
    this.productStore = new ProductStore(this.loaderStore);
    this.homePageStore = new HomePageStore(this.loaderStore);
    this.productStore = new ProductStore(this.loaderStore);
  }
}

export const store = new RootStore();
