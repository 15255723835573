import "./feedback.scss";
import { Alert, Button, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { PathBasedBreadcrumb } from "../PathBasedBreadcrumb/PathBasedBreadcrumb";
import { useState, useEffect } from "react";
import { sendFeedback } from "../../api/feedback";
import { useTranslation } from "react-i18next";

export const Feedback = () => {
  const [alert, setAlert] = useState<{
    type: "success" | "info" | "warning" | "error";
    message: string | null;
  }>({
    type: "success",
    message: null,
  });
  const [form] = useForm();

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    setTimeoutId(
      setTimeout(() => {
        setAlert({ type: "success", message: null });
      }, 5000)
    );
  }, [alert]);

  const submitForm = async (values: any) => {
    setAlert({ type: "info", message: t("submiting_feedback") });
    try {
      await sendFeedback({
        name: values.name,
        email: values.email.toLowerCase(),
        department: values.department,
        description: values.description,
      });
      setAlert({ type: "success", message: t("feedback_submitted") });
      form.resetFields();
    } catch (e) {
      setAlert({
        type: "error",
        message: t("feedback_error"),
      });
    }
  };

  return (
    <section className="p-feedback">
      <PathBasedBreadcrumb />
      <Form className="p-feedback__form" form={form} onFinish={submitForm}>
        {alert.message && <Alert {...alert} />}
        <label className="p-feedback__form-label" htmlFor="name">
          {t("feedback_name")}
        </label>
        <Form.Item name="name">
          <Input className="p-feedback__form-input" required />
        </Form.Item>
        <label className="p-feedback__form-label" htmlFor="email">
          {t("feedback_email")}
        </label>
        <Form.Item name="email" rules={[{ type: "email" }]}>
          <Input className="p-feedback__form-input" required />
        </Form.Item>
        <label className="p-feedback__form-label" htmlFor="department">
          {t("feedback_department")}
        </label>
        <Form.Item name="department">
          <Select
            className="p-feedback__form-input"
            defaultValue={"customer-support"}
          >
            <Select.Option value="sales">{t("feedback_sales")}</Select.Option>
            <Select.Option value="customer-support">
              {t("feedback_support")}
            </Select.Option>
          </Select>
        </Form.Item>
        <label className="p-feedback__form-label" htmlFor="description">
          {t("feedback_desc")}
        </label>
        <Form.Item name="description">
          <Input.TextArea className="p-feedback__form-input" required />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          className="p-feedback__form-submit"
        >
          {t("submit_feedback")}
        </Button>
      </Form>
    </section>
  );
};
