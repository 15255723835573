import { IProduct } from "~/common/models";
import { Button, Carousel } from "antd";

import "./Product.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getImageURL } from "../../common/utils/imageURLgetter";

interface Props extends IProduct {
  onAddToCart: (productID: string) => void;
  mode: "horizontal" | "vertical";
}

export const Product = ({
  mode,
  name,
  price,
  images,
  id,
  price_fiat,
  price_pi,
  paymentMode,
  fiatCurrency,
  onAddToCart,
}: Props) => {
  const shouldHaveImageCarousel = images?.length > 1;
  const { t } = useTranslation();
  function handleClick() {}

  const oldPrice = price;

  const productPrice = !!price_pi && price_pi > 0 ? price_pi : oldPrice;

  const paymentContainsPi =
    paymentMode === "pi" || paymentMode === "pi_fiat" || !paymentMode;

  const paymentContainsFIAT =
    paymentMode === "fiat" || paymentMode === "pi_fiat";


  switch (mode) {
    case "horizontal":
      return (
        <Link
          to={`/product/${id}`}
          onClick={() => handleClick()}
          className="c-product"
        >
          {shouldHaveImageCarousel ? (
            <Carousel autoplay className="c-product__image-carousel">
              {images?.map((imageLink, index) => (
                <img
                  src={getImageURL(imageLink)}
                  alt={name}
                  key={index}
                  className="c-product__image"
                />
              ))}
            </Carousel>
          ) : (
            <img
              src={getImageURL(images?.[0])}
              alt={name}
              className="c-product__image"
            />
          )}

          <div className="c-product__info">
            <div className="c-product__info-price">
              {paymentContainsPi && `${productPrice} Pi`}
              {paymentMode === "pi_fiat" && " - "}
              {paymentContainsFIAT && `${price_fiat} ${fiatCurrency}`}
            </div>
            <div className="c-product__info-title">
              <p>{name}</p>
            </div>
            {/* <div>
              <p className="c-product__info-quantity">1ps</p>
            </div> */}

            <Button
              onClick={() => onAddToCart(id)}
              className="c-product__button"
            >
              {t("myItems.add-item")}
            </Button>
          </div>
        </Link>
      );
    case "vertical":
      return (
        <Link
          to={`/product/${id}`}
          onClick={() => handleClick()}
          className="c-product c-product--vertical"
        >
          {shouldHaveImageCarousel ? (
            <Carousel autoplay className="c-product__image-carousel">
              {images?.map((imageLink, index) => (
                <img
                  src={getImageURL(imageLink)}
                  alt={name}
                  key={index}
                  className="c-product__image"
                />
              ))}
            </Carousel>
          ) : (
            <img
              src={getImageURL(images?.[0])}
              alt={name}
              className="c-product__image"
            />
          )}

          <div className="c-product__info">
            <div className="c-product__info-price">
              {paymentContainsPi && `${productPrice} Pi`}
              {paymentMode === "pi_fiat" && " - "}
              {paymentContainsFIAT && `${price_fiat} ${fiatCurrency}`}
            </div>
            <div className="c-product__info-title">
              {" "}
              <p>{name}</p>
            </div>
            {/* <div>
              <p className="c-product__info-quantity">1ps</p>
            </div> */}
          </div>
        </Link>
      );
  }
};
