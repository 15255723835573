import { ArrowRightOutlined } from "@ant-design/icons";
import { List, Modal, Spin } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./AppSettings.scss";
import { useState } from "react";
import { deleteAccount } from "../../api/profile";
import { PathBasedBreadcrumb } from "../PathBasedBreadcrumb/PathBasedBreadcrumb";

export const AppSettings = () => {
  const { t } = useTranslation();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const data = [
    // { title: t("Location_settings"), to: "/settings/location" },
    {
      title: t("Account_deletion"),
      onClick: async () => {
        setIsDeleteModalOpen(true);
      },
    },
  ];

  return (
    <div className="c-app-settings">
      <Modal
        open={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onOk={async () => {
          try {
            setIsDeleting(true);
            await deleteAccount();
          } catch (e) {
          } finally {
            setIsDeleteModalOpen(false);
            setIsDeleting(false);
            window.location.href = "/home-page";
          }
        }}
        title={t("delete_account.title")}
      >
        <Spin spinning={isDeleting}>
          <p>{t("delete_account.description")}</p>
        </Spin>
      </Modal>
      <div className="c-app-settings-header">
        <PathBasedBreadcrumb />
      </div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="c-store-panel_list"
        renderItem={(item, index) => (
          <List.Item onClick={() => item?.onClick?.()}>
            <List.Item.Meta
              title={<Link to={/*item?.to ||*/ "#"}>{item.title}</Link>}
            />
            <ArrowRightOutlined />
          </List.Item>
        )}
      />
    </div>
  );
};
