import { ProductStatus } from "~/common/models";
import { config, server } from "..";

export interface APIProduct {
  _id?: string;
  title?: string;
  images?: string[];
  condition?: string;
  category?: string;
  price?: number;
  description?: string;
  remember?: boolean;
  userId?: string;
  created_at?: number;
  location?: {
    coordinates: [number, number];
  };
  fiatCurrency?: string;
  paymentMode?: "pi" | "fiat" | "pi_fiat";
  price_pi?: number;
  price_fiat?: number;
  status?: ProductStatus;
}

export interface APIProductList {
  productObj: APIProduct[];
  userData?: {
    name: string;
    reviewsCount: number;
    rating: number;
    profileImage: string;
  };
}

export interface APIProducts {
  productObj: APIProduct;
  sellerData?: {
    name: string;
    reviewsCount: number;
    rating: number;
  };
}

export const getRandomProducts = async (
  skip: number,
  limit: number,
  location: [string, string]
): Promise<APIProductList> => {
  let query = location ? `?lat=${location[0]}&long=${location[1]}` : "";

  try {
    const { data } = await server.get(
      `/products/random/${skip}/${limit}${query}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching random products");
  }
};

export const getProductById = async (id: string): Promise<APIProducts> => {
  try {
    const { data } = await server.get(`/products/${id}`);

    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching product by id");
  }
};

export const getProductsBy = async (
  skip: number,
  limit: number,
  {
    category,
    condition,
    location,
  }: { category?: string; condition?: string; location?: string[] }
): Promise<APIProductList> => {
  try {
    let query1 = category ? `category=${category}` : "";
    let query2 = condition ? `condition=${condition}` : "";
    let query3 = location ? `lat=${location[0]}&long=${location[1]}` : "";

    let query = query1 || query2 ? "?" : "";
    query += query1 && query2 ? "&" : "";
    query += query1 + query2;
    query += query3 ? "&" : "";
    query += query3;

    const { data } = await server.get(`/products/${skip}/${limit}${query}`);

    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching products by category");
  }
};

export const getMyProducts = async (
  skip: number,
  limit: number
): Promise<APIProductList> => {
  try {
    const { data } = await server.get(
      `/products/user/current/${skip}/${limit}`,
      config
    );

    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching my products");
  }
};

export const getProductsForUser = async (
  userId: string,
  skip: number,
  limit: number
): Promise<APIProductList> => {
  try {
    const { data } = await server.get(
      `/products/user/${userId}/${skip}/${limit}`
    );

    return data;
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching products for user");
  }
};

export const deleteProduct = async (id: string): Promise<void> => {
  try {
    await server.delete(`/products/${id}`, config);
  } catch (error) {
    console.log(error);
    throw new Error("Error deleting product");
  }
};

export const searchProducts = async (
  skip: number,
  limit: number,
  querry: string,
  category: string,
  condition?: string,
  location?: string[]
): Promise<APIProductList> => {
  let BEquerry = `?querry=${querry}`;

  if (category) BEquerry += `&category=${category}`;
  if (condition)
    BEquerry += `&condition=${condition === "new" ? "new" : "used"}`;
  if (location?.length === 2)
    BEquerry += encodeURI(`&lat=${location[0]}&long=${location[1]}`);

  const { data } = await server.get(
    `/products/search/${skip}/${limit}${BEquerry}`
  );

  return data;
};
