import { ArrowRightOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { store } from "store/index.store";

import "./CategoriesPage.scss";

export const CategoriesPage = observer(() => {
  const { UIStore, loaderStore } = store;
  const { categories, loadCategories } = UIStore;

  const navi = useNavigate();

  useEffect(() => {
    loadCategories();
  }, []);

  return (
    <div className="c-category-page">
      {/**category selector */}
      {/* <Row className="c-category-page__header-row">
        <Col span={12} key="1">
          <Button
            className={classNames(
              "c-category-page__button",
              "c-category-page__button--left",
              {
                "c-category-page__button--selected": selectedCategory === "new",
                "c-category-page__button--unselected":
                  selectedCategory === "used",
              }
            )}
            onClick={() => setSelectedCategory("new")}
          >
            New
          </Button>
        </Col>
        <Col span={12} key="2">
          <Button
            className={classNames(
              "c-category-page__button",
              "c-category-page__button--right",
              {
                "c-category-page__button--selected":
                  selectedCategory === "used",
                "c-category-page__button--unselected":
                  selectedCategory === "new",
              }
            )}
            onClick={() => setSelectedCategory("used")}
          >
            Used
          </Button>
        </Col>
      </Row> */}
      <Spin spinning={loaderStore.isLoadingCategories}>
        <div className="c-category-page__list">
          {categories &&
            categories.map((cat) => {
              return (
                <div
                  key={cat._id}
                  className="c-category-page__list-item"
                  onClick={() => navi(`/category/${cat._id}`)}
                >
                  <div className="c-category-page__list-item-text">
                    {cat.name}
                  </div>
                  <ArrowRightOutlined className="c-category-page__list-item-arrow" />
                </div>
              );
            })}
        </div>
      </Spin>
    </div>
  );
});
