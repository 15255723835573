import { Button, List, Row } from "antd";
import { observer } from "mobx-react-lite";

import shopCard from "../../assets/images/shop-card.png";

import "./StoresPage.scss";
import {
  FilterOutlined,
  LockOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const StoresPage = observer(() => {
  const { t } = useTranslation();

  const data = [
    { title: { shopCard }, to: "" },
    { title: { shopCard }, to: "" },
    { title: { shopCard }, to: "" },
    { title: { shopCard }, to: "" },
    { title: { shopCard }, to: "" },
    { title: { shopCard }, to: "" },
  ];

  return (
    <div className="c-stores-page">
      <div className="c-stores-page__message">
        <LockOutlined size={50} />
        <br />
        {t("underDevelopment")}
      </div>
      <div className="c-stores-page__over"></div>
      <div className="c-stores-page_filter-buttons">
        <Row>
          <Button
            shape="circle"
            className="c-stores-page_filter-buttons-button"
          >
            All
          </Button>
          <Button type="link" className="c-stores-page_filter-buttons-button">
            Sports
          </Button>
          <Button type="link" className="c-stores-page_filter-buttons-button">
            Outdoors
          </Button>
          <div className="c-stores-page_filter-search">
            <FilterOutlined className="c-stores-page_filter-search-search" />
            <SearchOutlined className="c-stores-page_filter-search-search" />
          </div>
        </Row>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="c-stores-page_list"
        renderItem={(item, index) => (
          <List.Item>
            <div className="c-stores-page_list-first">
              <Link to={"#" /*/storeProducts*/}>
                <img
                  src={shopCard}
                  alt="shop"
                  className="c-stores-page_row-image"
                />
              </Link>
            </div>
            <div className="c-stores-page_list-second">
              <Link to={"#" /*/storeProducts*/}>
                <img
                  src={shopCard}
                  alt="shop"
                  className="c-stores-page_row-image"
                />
              </Link>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
});
