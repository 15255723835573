import { Button, Checkbox, Input, Row } from "antd";
import Card from "antd/lib/card/Card";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";


import "./StoreLogIn.scss";

export const StoreLogIn = observer(() => {
  return(
    <div className="c-login__background" >
        <Card className="c-login__login-page">
            <label className="c-login__login-page_login-label">Log-In User </label>
            <label className="c-login__login-page_email-address">Email address</label>
            <Input className="c-login__login-page_email-address-input" placeholder="JohnDoe@reallyimportantcompany.com" />
            <label className="c-login__login-page_password">Password</label>
            <Input type="password" className="c-login__login-page_password-input" placeholder="*****************" />
            <Button type="link" className="c-login__login-page_forgot-password">Forgot password</Button>
            <Row className="c-login__login-page_signed-checkbox">
              <Checkbox>Keep me signed in on this device.</Checkbox>
            </Row>
            <Row className="c-login__login-page_condition-checkbox">
              <Checkbox>I Agree to <Link to={""} className="c-login__login-page_condition-checkbox-link">Terms and Conditions</Link></Checkbox>
            </Row>
            <Row >
              <Button type="primary" shape="round" className="c-login__login-page_login-button">
                <Link to={"/StorePanelList"}>
                  Login
                </Link>
              </Button>
            </Row>
            <Row className="c-login__login-page_register">
              <Button shape="round" className="c-login__login-page_register-button">Register</Button>
            </Row>
            <Button type="link" className="c-login__login-page_faq-button">FAQ</Button>
        </Card>
    </div>
  );
});
