import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { List } from "antd";
import { Link } from "react-router-dom";

import "./WatizenList.scss";


export const WatizenList = () => {

    const data = [
        {title: 'Dashboard',to:"/watizenDashboard"},
        {title:'My stores', to: ""},
        {title: 'My earnings',to:""},
        {title:'Watizen code', to: "/watizenCode"},
        {title:'How it works', to: ""}];

        return (
            <div className="c-watizen-list">
                <div className="c-watizen-list-header">
                    <ArrowLeftOutlined />
                    <label> Watizen </label>
                </div>
                <List itemLayout="horizontal" dataSource={data} className="c-store-panel_list"
                    renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta title={<Link to={item.to}>{item.title}</Link>} />
                    <ArrowRightOutlined />
                </List.Item>
            )} />
            </div>
        )
}