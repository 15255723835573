import { UserOutlined } from "@ant-design/icons";
import { Alert, Button, Checkbox, Input, Spin } from "antd";
import Card from "antd/lib/card/Card";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { store } from "../../store/index.store";

import "./LogIn.scss";
import { useTranslation } from "react-i18next";

export const LogIn = observer(() => {
  const navigator = useNavigate();
  const { t } = useTranslation();

  const { authStore, loaderStore } = store;

  const { isLoggedIn, login, setForm, form, authError } = authStore;

  if (isLoggedIn) navigator("/home-page");

  const handleLogin = () => {
    try {
      login();
    } catch (e) {}
  };

  return (
    <div className="c-login__background">
      <Card
        className="c-login__login-page"
        bodyStyle={{ margin: "0 auto", maxWidth: "650px", width: "100vw" }}
      >
        <label className="c-login__login-page_login-label">
          {t("login.log-in")}
        </label>
        {authError && (
          <>
            <br />
            {authError ? <Alert message={authError} type="error" /> : <br />}
          </>
        )}

        <label className="c-login__login-page_email-address">
          {t("login.email")}
        </label>
        <Input
          onChange={(e) => {
            setForm("email", e.target.value);
          }}
          value={form.email}
          className="c-login__login-page_email-address-input"
          placeholder="email@email.com"
          suffix={<UserOutlined />}
        />
        <label className="c-login__login-page_password-label">
          {" "}
          {t("login.password")}
        </label>
        <Input.Password
          onChange={(e) => {
            setForm("password", e.target.value);
          }}
          value={form.password}
          className="c-login__login-page_password-input"
          placeholder="*****************"
        />
        <Button
          className="c-login__login-page_forgot-password"
          onClick={() => navigator("/reset-password")}
        >
          {t("login.forgot-password")}
        </Button>
        <br />
        <Checkbox
          className="c-login__login-page_terms-condition-checkbox"
          checked={form.agree}
          onChange={(val) => setForm("agree", val.target.checked)}
        >
          {t("login.agree")}{" "}
          <a
            href="/Watugot-Privacy_Policy.docx"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sidebar.privacyPolicy")}
          </a>
          &nbsp;
          {t("sidebar.and")}&nbsp;
          <a
            href="/Watugot-Terms_and_Conditions.docx"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("sidebar.termsAndConditions")}
          </a>
        </Checkbox>
        <div>
          <Spin spinning={loaderStore.isLoggingIn}>
            <Button
              type="primary"
              shape="round"
              className="c-login__login-button"
              onClick={handleLogin}
            >
              {t("sidebar.login")}
            </Button>
          </Spin>
        </div>
        <div>
          <Button
            type="primary"
            shape="round"
            className="c-login__register-button"
            onClick={() => navigator("/login-register")}
            style={{ color: "#159a8c", borderColor: "#159a8c" }}
          >
            {t("login.register")}
          </Button>
        </div>
      </Card>
    </div>
  );
});
