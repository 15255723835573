import { Modal, Spin } from "antd";
import "./ItemsActivityPage.scss";
import { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { getMyTransactions } from "../../api/transactions";
import { observer } from "mobx-react-lite";
import { store } from "../../store/index.store";
import { PathBasedBreadcrumb } from "../../components/PathBasedBreadcrumb/PathBasedBreadcrumb";
import { useTranslation } from "react-i18next";

export interface ITransactionEntry {
  _id: string;
  userId: string;
  recivingUid: string;
  productId: string;
  amount: number;
  productName: string;
  status: string;
  createdAt: number;
  buyerPaymentStatus: string;
  piId: string;
}

const TXStatusMap: Record<string, string> = {
  not_started: "Not started",
  pending: "Pending",
  completed: "Completed",
  failed: "Failed",
  completed_from_cancel: "Completed from cancel",
  seller_completed: "Seller completed",
};

export const ItemsActivityPage = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [transactions, setTransactions] = useState<ITransactionEntry[]>([]);

  const currentlyLoadedProducts = useRef(0);
  const [recipt, setRecipt] = useState<string | null>(null);

  const { t } = useTranslation();

  const { authStore } = store;

  const loadTransactions = async () => {
    try {
      setIsLoading(true);
      let transactionsReq: ITransactionEntry[] = [];

      const { data } = await getMyTransactions(
        currentlyLoadedProducts.current,
        currentlyLoadedProducts.current + 20
      );

      transactionsReq = data.payments;

      console.log(transactionsReq);

      currentlyLoadedProducts.current =
        transactions?.length + transactionsReq?.length;

      setTransactions((prods) => [...prods, ...transactionsReq]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadTransactions();
  }, []);

  useBottomScrollListener(
    () => {
      loadTransactions();
    },
    {
      offset: 0,
      debounce: 200,
      debounceOptions: { leading: true },
      triggerOnNoScroll: false,
    }
  );

  const openedRecipt = transactions.find((t) => t._id === recipt);
  return (
    <div>
      <PathBasedBreadcrumb />
      <Modal
        open={!!recipt}
        title={t("your_reciept")}
        onCancel={() => {
          setRecipt(null);
        }}
        onOk={() => {
          setRecipt(null);
        }}
      >
        {openedRecipt && (
          <>
            <div>
              <b>TxId:</b> {openedRecipt?._id}
            </div>
            <div>
              <b>Transaction type: </b>
              {authStore.userProfile.userId === openedRecipt.userId
                ? "Bought"
                : "Sold"}
            </div>
            <div>
              <b>Payment Status:</b> {TXStatusMap[openedRecipt?.status]}
            </div>
            <div>
              <b>Seller Payment Status:</b>{" "}
              {TXStatusMap[openedRecipt?.buyerPaymentStatus]}
            </div>
            <div>
              <b>Date:</b>{" "}
              {new Date(openedRecipt?.createdAt).toLocaleDateString()}
            </div>
            <div>
              <b>Amount:</b> {openedRecipt?.amount} PI
            </div>
            <div>
              <b>Product:</b> {openedRecipt?.productName}
            </div>

            <div>
              <b>PiId:</b> {openedRecipt?.piId}
            </div>
            <div>
              <b>UserId:</b> {openedRecipt?.userId}
            </div>
            <div>
              <b>RecivingUid:</b> {openedRecipt?.recivingUid}
            </div>
            <div>
              <b>ProductId:</b> {openedRecipt?.productId}
            </div>
          </>
        )}
      </Modal>
      <Spin spinning={isLoading}>
        <div className="c-items-activity-page__list">
          {transactions.map((transaction) => (
            <div key={transaction.piId} className="c-items-activity-page__item">
              <div
                className="c-items-activity-page__item-id"
                onClick={() => {
                  setRecipt(transaction._id);
                }}
              >
                ...
                {transaction._id.slice(
                  transaction._id.length - 6,
                  transaction._id.length
                )}
              </div>
              <div className="c-items-activity-page__item-date">
                {new Date(transaction.createdAt).toLocaleDateString()}
              </div>
              <div className="c-items-activity-page__item-ammount">
                {authStore.userProfile.userId === transaction.userId
                  ? "Dr "
                  : "Cr "}
                {transaction.amount} PI
              </div>
            </div>
          ))}
        </div>
      </Spin>
    </div>
  );
});
