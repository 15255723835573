import { ArrowLeftOutlined } from "@ant-design/icons";
import "./ProfileSetting.scss";

export const StorePanel = () => {
    
    return (
        <div>
            <ArrowLeftOutlined />
            <label> Store Panel </label>
        </div>
      );
}