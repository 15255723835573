import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, List } from "antd";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import "./FeaturedListings.scss";


const data = [
    {title: 'Item #1 A00124',date:'July, 5th', isActive:false},
    {title:'Item #2 A00124', date: 'July, 5th', isActive:true},
    {title:'Item #3 A00124', date:'July, 5th', isActive:true}];

 export const FeaturedListings = observer(() => {
    return(
        <>
            <div className="c-featured-listings_list" style={{padding:"20px"}}>
                <Link to={"/storePanelList"} style={{color:"black"}}>
                    <ArrowLeftOutlined />
                </Link>
                <label> Store Name/Featured </label>
            </div>
            <List itemLayout="horizontal" dataSource={data} className="c-featured-listings_list"
            renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta title={item.title} />
                    <div style={{marginRight:"20vw"}}>{item.date}</div>
                    <div style={{marginLeft:10, padding:10}}>
                        <Button shape="circle" disabled={!item.isActive}>active</Button>
                    </div>
                </List.Item>
            )} />
        </>
    )
 });