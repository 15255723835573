import { Area } from "@ant-design/charts";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { Card, Col, Row, Statistic } from "antd";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import "./StoreDashboard.scss";

export const StoreDashboard = observer(() => {
  // let chart;

  const data = [
    { amount: 80, day: 0 },
    { amount: 70, day: 1 },
    { amount: 60, day: 2 },
    { amount: 20, day: 3 },
    { amount: 75, day: 4 },
    { amount: 65, day: 5 },
    { amount: 55, day: 6 },
    { amount: 24, day: 7 },
  ];

  const config = {
    data,
    width: 600,
    height: 300,
    autofit: true,
    beginAtZero: false,
    xField: "day",
    yField: "amount",
    point: {
      size: 2,
      shape: "circle",
    },
    label: {
      style: {
        fill: "#aaa",
      },
    },
  };

  return (
    <div className="c-store-dashboard">
      <div className="c-store-dashboard" style={{ padding: "10px" }}>
        <Link
          to={"/storePanelList"}
          style={{ color: "black", padding: "10px" }}
        >
          <ArrowLeftOutlined />
        </Link>
        <label> Store Panel List </label>
      </div>
      <Area
        {...config}
        onReady={(chartInstance) => chartInstance}
        className="c-store-dashboard-chart"
      />
      <Row className="c-store-dashboard-chart-label">
        <label>Earned per day</label>
      </Row>
      <Row gutter={4}>
        <Col span={12}>
          <Card bordered={true} className="c-store-dashboard-card1">
            <Statistic
              value={"140"}
              suffix={"Earned Today"}
              valueStyle={{ color: "#159A8C", fontWeight: "bold" }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={true} className="c-store-dashboard-card1">
            <Statistic
              value={"1.5k"}
              suffix={"Earned This month"}
              valueStyle={{ color: "#159A8C", fontWeight: "bold" }}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={4}>
        <Col span={12}>
          <Card bordered={true} className="c-store-dashboard-card1">
            <Statistic
              value={"420"}
              suffix={"Max Per day"}
              valueStyle={{ color: "#159A8C", fontWeight: "bold" }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={true} className="c-store-dashboard-card1">
            <Statistic
              value={"15"}
              suffix={"Eco-Friendly Bonus"}
              valueStyle={{ color: "#159A8C", fontWeight: "bold" }}
            />
          </Card>
        </Col>
      </Row>
      <div className="c-store-dashboard-edit">
        <Row className="c-store-dashboard-edit-row">
          <label className="c-store-dashboard-edit-store">
            Edit store information
          </label>
          <ArrowRightOutlined className="c-store-panel_list-logout-arrow" />
        </Row>
        <Row className="c-store-dashboard-edit-row">
          <label className="c-store-dashboard-edit-store">
            Set Store Featured
          </label>
          <ArrowRightOutlined className="c-store-panel_list-logout-arrow" />
        </Row>
        <Row className="c-store-dashboard-edit-row">
          <label className="c-store-dashboard-edit-deactivate">
            Deactivate store
          </label>
          <CloseOutlined className="c-store-dashboard-edit-deactivate-close" />
        </Row>
      </div>
    </div>
  );
});
