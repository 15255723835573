import { ArrowLeftOutlined } from "@ant-design/icons";
import { Radio, Space, Button } from "antd";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { store } from "store/index.store";
import england from "../../assets/images/england.png"
import india from "../../assets/images/india.png"

import "./LanguageSetting.scss";

export const LanguageSetting = observer(() => {
    const { UIStore } = store;
    
    function showAddress() {
        if (!UIStore.showLanguage) {
            
        } else {
            UIStore.setShowLanguage(false);   
        }
    }
  
    return(
        <div className="c-language-setting">
            <div className="c-language-setting__header-navigation">
                <ArrowLeftOutlined />
                <label style={{margin:"20px", font:"20px", fontWeight:500}}>Language settings</label>
            </div>
            { UIStore.showLanguage &&
                <div className="c-language-setting__radio-space">
                    <Radio.Group style={{flexDirection:"row", padding:"20px"}} size={'large'} >
                        <Space direction="vertical" >
                            <Radio.Button value={1} className="c-language-setting__radio-space-option">
                                <img alt="england" src={england} width="30px" height={"30px"} style={{padding:"5px"}}/>
                                English
                            </Radio.Button>
                            <Radio.Button value={2} className="c-language-setting__radio-space-option">
                                <img alt="india" src={india} width="30px" height={"30px"} style={{padding:"5px"}}/>
                                Hindi
                            </Radio.Button>
                        </Space>
                    </Radio.Group>
                </div>
            }
            { !UIStore.showLanguage && 
                <div className="c-language-setting__radio-address">
                    <Radio.Group style={{flexDirection:"row", padding:"20px"}} size={'large'} >
                        <Space direction="vertical" >
                            <Radio.Button value={1} className="c-language-setting__radio-address-option">
                                <text>33rd, Bottom Ast. 123456 Washington DC.apt 13</text>
                            </Radio.Button>
                            <Radio.Button value={2} className="c-language-setting__radio-address-option">
                                <text>33rd, Bottom Ast. 123456 Washington DC.apt 13</text>
                            </Radio.Button>
                        </Space>
                    </Radio.Group>
                </div>
            }
            {
                !UIStore.showLanguage ?
                <div>
                    <Link to={"/homePage"}>
                        <Button type="primary" shape="round" className="c-language-setting__login-button" onClick={() => showAddress()}>Continue</Button>
                    </Link>
                </div> :
                <div>
                    <Button type="primary" shape="round" className="c-language-setting__login-button" onClick={() => showAddress()}>Continue</Button>
                </div>
            }
            { !UIStore.showLanguage && 
                <div>
                    <Link to={"/addAddress"}>
                        <Button type="primary" shape="round" className="c-language-setting__add-address-button">Add an address</Button>
                    </Link>
                </div>
            }
        </div>
    );
});
