import { updatePiProfile } from "../api/profile";
import { config, server } from "..";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";

export const PiHandler = observer(() => {
  const scopes = ["username", "payments", "wallet_address"];

  const onIncompletePaymentFound = (payment: any) => {
    console.log(payment, payment.identifier, "onIncompletePaymentFound");
    return server.post(
      `/cancel/paymentId/${payment.identifier}`,
      {
        txid: payment.transaction.txid,
      },
      config
    );
  };

  useEffect(() => {
    setTimeout(() => {
      window.Pi.authenticate(scopes, onIncompletePaymentFound)
        .then(function (auth: any) {
          alert(
            `Welcome to Watugot.\nWelcome to the world of Pi.\nYou are sucessfully logged in as ${auth.user.username}`
          );

          window.userData = auth;

          window.isPiAuth = true;

          if (!window.updaterInterfal)
            window.updaterInterfal = setInterval(() => {
              if (!window?.userData?.accessToken) return;
              if (!localStorage.getItem("token")) return;
              if (config.headers.Authorization === `Bearer null`) return;

              updatePiProfile(
                window?.userData?.accessToken,
                window?.userData?.wallet_address
              );

              if (window.updaterInterfal) clearInterval(window.updaterInterfal);
            }, 1000);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }, 1000);
  }, []);

  return <></>;
});
