export const dateDeltaTextGen = (date1: number, date2: number) => {
  let diff = Math.floor(date1 - date2);
  let day = 1000 * 60 * 60 * 24;

  let days = Math.floor(diff / day);
  let months = Math.floor(days / 31);
  let years = Math.floor(months / 12);

  let message = "Customer since: ";

  const yearsMsg = years + " years \n";
  const monthsMsg = months + " months ";
  const daysMsg = days + " days ago";

  if (years > 0) {
    message += yearsMsg;
  }
  if (months > 0) {
    message += monthsMsg;
  }
  if (days > 0) {
    message += daysMsg;
  }

  if (years === 0 && months === 0 && days === 0) {
    message = "Thank you for being a new customer!";
  }

  return message;
};
