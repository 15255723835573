import { ArrowLeftOutlined } from "@ant-design/icons";
import "./ProfileSetting.scss";

export const Notifications = () => {
    
    return (
        <div>
            <ArrowLeftOutlined />
            <label>Notifications</label>
        </div>
      );
}