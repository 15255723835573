import { UploadOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  Checkbox,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Spin,
  Upload,
} from "antd";

import "./PostItemPage.scss";
import { store } from "../../store/index.store";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import type { UploadFile, UploadProps } from "antd";
import type { RcFile } from "antd/es/upload";
import { uploadImage } from "../../api/image";
import { useTranslation } from "react-i18next";
import { APIProducts, getProductById } from "../../api/products";
import { useForm, useWatch } from "antd/lib/form/Form";
import { getImageURL } from "../../common/utils/imageURLgetter";
import { PathBasedBreadcrumb } from "../../components/PathBasedBreadcrumb/PathBasedBreadcrumb";
import { WalletAddrModal } from "../../components/WalletAddrModal/WalletAddrModal";
import CurrencyList from "currency-list";
import { CODE_TO_ISO, ISO_TO_CURRENCY } from "../../common/constants/languages";
import { PaymentReason } from "../Payment/Payment";
import { ProductStatus } from "../../common/models";

const { Option } = Select;

const onFinishFailed = (errorInfo: any) => {
  console.log("Failed:", errorInfo);
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const PostItemPage = observer(() => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();
  const [error, setError] = useState<string | null>("");

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { id } = useParams();

  const isEditMode = !!id;

  const selectedCurrency = localStorage.getItem("selectedCurrency");

  const { i18n } = useTranslation();
  const locale = CODE_TO_ISO[i18n.language];
  const currentCountryCurrency = ISO_TO_CURRENCY[locale];

  const defaultValue = selectedCurrency || currentCountryCurrency || "USD";

  const [fiatCurrency, setFiatCurrency] = useState(defaultValue);

  const SelectAfter = () => {
    const currencies = CurrencyList.getAll(locale);

    if (fiatCurrency !== defaultValue) {
      setFiatCurrency(defaultValue);
    }

    return (
      <Select
        defaultValue={defaultValue}
        style={{ width: 110 }}
        onSelect={(value) => {
          localStorage.setItem("selectedCurrency", value);
          setFiatCurrency(value);
        }}
      >
        {Object.keys(currencies).map((key) => (
          <Option
            key={currencies[key].code?.toString()}
            value={currencies[key].code}
          >
            {currencies[key].code.toString()} -{" "}
            {currencies[key].symbol.toString()}
          </Option>
        ))}
      </Select>
    );
  };

  useEffect(() => {
    if (id) {
      getProductById(id).then((product: APIProducts) => {
        Object.keys(product.productObj).forEach((key) => {
          if (key === "images" && product?.productObj?.images) {
            const images = product?.productObj?.images.map(
              (item: string, index) => ({
                uid: item,
                name: `${form.getFieldValue("title")}-${index}`,
                status: "done",
                url: getImageURL(item, false),
              })
            );

            //@ts-ignore - type incompatible, but it works
            setFileList(images);
          }

          //@ts-ignore
          form.setFieldValue(key, product.productObj[key]);
          setFiatCurrency(product.productObj.fiatCurrency || defaultValue);
          if (product.productObj.paymentMode)
            setPaymentMode(product.productObj.paymentMode);
        });
      });
    }
  }, [id]);

  const handleChange: UploadProps["onChange"] = (info) => {
    let newFileList = [...info.fileList];

    // 1. Limit the number of uploaded files
    // Only to show two recent uploaded files, and old ones will be replaced by the new
    newFileList = newFileList.slice(-3);

    // 2. Read from response and show file link
    newFileList = newFileList.map((file) => {
      if (file.response) {
        // Component will show file.url as link
        file.url = file.response.url;
      }
      return file;
    });

    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url!.substring(file.url!.lastIndexOf("/") + 1)
    );
  };

  let [paymentMode, setPaymentMode] = useState("pi");

  const formPaymentMode = useWatch("paymentMode", form);

  useEffect(() => {
    setPaymentMode(formPaymentMode);
  }, [formPaymentMode]);

  // const wantsPromotion = useWatch("promote", form);

  const paymentContainsPi =
    paymentMode === "pi" || paymentMode === "pi_fiat" || !paymentMode;

  const paymentContainsFIAT =
    paymentMode === "fiat" || paymentMode === "pi_fiat";

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { productStore, UIStore, loaderStore, authStore } = store;

  const [walletModalState, setWalletModalState] = useState(false);

  useEffect(() => {
    UIStore.loadCategories();
  }, []);

  const onFinish = async (form_values: any) => {
    if (!authStore.userProfile?.walletAddress && paymentContainsPi) {
      return setWalletModalState(true);
    }
    setError(null);

    const values = { ...form_values };

    if (paymentContainsFIAT) {
      values.fiatCurrency = fiatCurrency;
    }

    if (isEditMode) {
      const locationObj = values.updateLocation
        ? {
            location: authStore.location?.slice(0, 2).join("-"),
          }
        : {};

      await productStore.editItem({
        values: {
          ...values,
          ...locationObj,
          paymentMode,
          images: fileList.map(
            (item: any) =>
              //@ts-ignore
              new URL(item.url).pathname.replace("/", "").split(".")[0]
          ),
        },
        id,
      });
    } else {
      const data = await productStore.postNewItem({
        values: {
          ...values,
          location: authStore.location?.slice(0, 2).join("-"),
          images: fileList.map(
            (item: any) =>
              //@ts-ignore
              new URL(item.url).pathname.replace("/", "").split(".")[0]
          ),
        },
      });

      if (paymentContainsFIAT) {
        if (authStore.userProfile?.freebies?.freeFiatListings > 0)
          authStore.decrementFreeFiatListings();

        if (data.data.message.status === ProductStatus.needs_fiat_tax)
          navigate(
            `/payment/${PaymentReason.fiat_listing_fee}/${data.data.message.id}`
          );
      }
    }

    setIsModalOpen(true);
  };

  const handleCancel = () => setPreviewOpen(false);

  function handleOKSuccesModal() {
    setIsModalOpen(false);
    navigate("/home-page");
  }

  return (
    <div className="p-post-item-page">
      {error && <Alert message={error} type="error" showIcon />}
      <WalletAddrModal
        state={walletModalState}
        setState={setWalletModalState}
      />
      <Modal
        className="p-post-item-page__confirmation-modal"
        title={t("product_posted_title")}
        open={isModalOpen}
        onOk={handleOKSuccesModal}
      >
        <div dangerouslySetInnerHTML={{ __html: t("product_posted") || "" }} />
      </Modal>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
      <PathBasedBreadcrumb />

      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        className="p-post-item-page__form"
        form={form}
      >
        <Form.Item
          label={t("postItem.title")}
          name="title"
          rules={[
            {
              required: true,
              message: "You cannot let this field empty",
            },
            {
              max: 50,
              message: "Title is over 50 characters",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("postItem.picture")}
          name="images"
          rules={[
            { required: true, message: "You cannot let this field empty" },
          ]}
        >
          <Upload
            beforeUpload={(file) => {
              setError(null);
              const formats = [
                "image/jpeg",
                "image/jpg",
                "image/png",
                "image/webp",
              ];
              const isImage = formats.includes(file.type);

              if (!isImage) {
                setError(
                  "You can only upload images (jpg,jpeg,png,webp) file!"
                );
              }
              return isImage || Upload.LIST_IGNORE;
            }}
            customRequest={async (options) => {
              const { onSuccess, onError, file } = options;

              const fmData = new FormData();
              const config = {
                headers: { "content-type": "multipart/form-data" },
              };
              fmData.append("image", file);
              try {
                const { data } = await uploadImage(fmData, config);
                const newLocation = data.data;

                //@ts-ignore
                onSuccess({ ...file, url: newLocation });
              } catch (err) {
                //@ts-ignore
                onError({ event: err });
              }
            }}
            onChange={handleChange}
            multiple={true}
            fileList={fileList}
            onPreview={handlePreview}
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>{" "}
          </Upload>
        </Form.Item>
        <Form.Item
          label={t("postItem.condition")}
          name="condition"
          rules={[
            { required: true, message: "You cannot let this field empty" },
          ]}
        >
          <Select style={{ height: "40px" }}>
            <Select.Option value="new">New</Select.Option>
            <Select.Option value="used">Used</Select.Option>
          </Select>
        </Form.Item>

        <Spin spinning={loaderStore.isLoadingCategories}>
          <Form.Item
            label="Category"
            name="category"
            rules={[
              { required: true, message: "You cannot let this field empty" },
            ]}
          >
            <Select>
              {UIStore?.categories?.map((item) => (
                <Select.Option key={item._id} value={item._id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Spin>

        {!isEditMode && (
          <Form.Item
            label={t("postItem.mode")}
            name="paymentMode"
            rules={[
              { required: true, message: "You cannot let this field empty" },
            ]}
          >
            <Select style={{ height: "40px" }}>
              <Select.Option value="pi">PI</Select.Option>
              {/* <Select.Option value="pi_fiat">PI+FIAT</Select.Option> */}
              <Select.Option value="fiat">FIAT</Select.Option>
            </Select>
          </Form.Item>
        )}

        {paymentContainsPi && (
          <Form.Item
            label={t("postItem.price_pi")}
            name="price_pi"
            rules={[
              { required: true, message: "You cannot let this field empty" },
              //  , {
              //     //min price
              //     validator: (_, value) =>
              //       value >= 1
              //         ? Promise.resolve()
              //         : Promise.reject(
              //             new Error("Price must be greater than or equal to 1 PI")
              //           ),
              //   },
            ]}
          >
            <InputNumber
              addonBefore=""
              addonAfter=""
              placeholder="Pi"
              min={0}
              style={{ width: "100%" }}
            />
          </Form.Item>
        )}

        {paymentContainsFIAT && (
          <>
            <Form.Item
              className="p-post-item-page__fiat-input-value"
              label={t("postItem.price_fiat")}
              name="price_fiat"
              rules={[
                { required: true, message: "You cannot let this field empty" },
              ]}
            >
              <InputNumber
                addonBefore=""
                addonAfter={<SelectAfter />}
                placeholder="FIAT"
                min={0}
                style={{
                  width: "100%",
                }}
              />
            </Form.Item>
          </>
        )}

        <Form.Item
          label={t("postItem.description")}
          name="description"
          rules={[
            {
              required: true,
              message: "You cannot let this field empty",
            },
            {
              max: 500,
              message: "Description is over 500 characters",
            },
          ]}
        >
          <Input.TextArea style={{ height: 60 }} />
        </Form.Item>

        {/* <Form.Item
          label={t("postItem.promote")}
          name="promote"
          valuePropName="checked"
          wrapperCol={{ offset: 1, span: 16 }}
        >
          <Checkbox></Checkbox>
        </Form.Item> */}

        {paymentContainsFIAT && (
          <>
            <span className="p-post-item-page__fiat-warning">
              <b>{authStore.userProfile?.freebies?.freeFiatListings || 0}</b>{" "}
              free Fiat listing left
            </span>
            {(authStore.userProfile?.freebies?.freeFiatListings || 0) === 0 &&
              !isEditMode && (
                <div className="p-post-item-page__fees">
                  <div className="p-post-item-page__fees-fiat">
                    <span>{t("postItem.fiat_tax")}</span>
                    <span>0.1 PI</span>
                  </div>
                  <div className="p-post-item-page__fees-total">
                    <span>{t("postItem.total")}</span>
                    <span className="p-post-item-page__fees-total-value">
                      0.1 PI
                    </span>
                  </div>
                </div>
              )}
          </>
        )}

        <Form.Item
          name="accTcC"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Should accept agreement")),
            },
          ]}
          wrapperCol={{ offset: 1, span: 16 }}
        >
          <Checkbox defaultChecked={false}>
            <span
              dangerouslySetInnerHTML={{ __html: t("postItem.agree") || "" }}
            />
          </Checkbox>
        </Form.Item>

        <Form.Item
          name="buyerReviewAck"
          valuePropName="checked"
          wrapperCol={{ offset: 1, span: 16 }}
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error("You must receive a review from the buyer")
                    ),
            },
          ]}
        >
          <Checkbox>{t("postItem.remember")}</Checkbox>
        </Form.Item>

        {paymentMode === "fiat" && (
          <Form.Item
            name="fiatItemDelete"
            valuePropName="checked"
            wrapperCol={{ offset: 1, span: 16 }}
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error("You should delete the listing once sold")
                      ),
              },
            ]}
          >
            <Checkbox>
              I agree to delete the listing from my items page once the item is
              sold.
            </Checkbox>
          </Form.Item>
        )}

        {isEditMode && (
          <Form.Item
            name="updateLocation"
            valuePropName="checked"
            wrapperCol={{ offset: 1, span: 16 }}
          >
            <Checkbox>{t("postItem.updateLocation")}</Checkbox>
          </Form.Item>
        )}

        {paymentContainsPi && (
          <p style={{ width: "90%", margin: "10px auto" }}>
            {t("postItem.note")}
          </p>
        )}

        <Form.Item wrapperCol={{ offset: 1, span: 100 }}>
          <Button
            type={
              paymentContainsFIAT &&
              (authStore.userProfile?.freebies?.freeFiatListings || 0) === 0 &&
              !isEditMode
                ? "default"
                : "primary"
            }
            htmlType="submit"
            style={{ width: "90%", borderRadius: "10px", height: "40px" }}
          >
            {isEditMode
              ? "Edit item"
              : paymentContainsFIAT &&
                (authStore.userProfile?.freebies?.freeFiatListings || 0) === 0
              ? t("postItem.checkout")
              : t("postItem.post")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
});
