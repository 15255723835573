import { config, server } from "..";

export const sendMessage = async (
  message: string,
  chatId: string
): Promise<void> => {
  return server.post(`/messages/chat/${chatId}`, { message }, config);
};

export const sendMessageToProduct = async (
  message: string,
  productId: string
): Promise<void> => {
  return server.post(`/messages/product/${productId}`, { message }, config);
};

export const getChats = async (skip: number, limit: number) => {
  return server.get(`/messages/${skip}/${limit}`, config);
};

export const getMessagesForChat = async (
  chatId: string,
  skip: number,
  limit: number
) => {
  return server.get(`/messages/chat/${chatId}/${skip}/${limit}`, config);
};

export const getMessagesForProductId = async (
  productId: string,
  skip: number,
  limit: number
) => {
  return server.get(`/messages/product/${productId}/${skip}/${limit}`, config);
};

export const getChatInfo = async ({
  chatId,
  productId,
}: {
  chatId?: string;
  productId?: string;
}) => {
  const querry = new URLSearchParams();

  if (chatId) {
    querry.append("chatId", chatId);
  }

  if (productId) {
    querry.append("productId", productId);
  }

  return server.get(`/messages/chat/info?${querry.toString()}`, config);
};

export const deleteChat = async (chatId: string) => {
  return server.delete(`/messages/chat/${chatId}`, config);
};

export const deleteChatFromProdId = async (productId: string) => {
  return server.delete(`/messages/product/${productId}`, config);
};
