/**
 *  "en",
      "ar",
      "de",
      "nl",
      "fr",
      "hi",
      "id",
      "ja",
      "ko",
      "pl",
      "sp",
      "vi",
      "zh",
 */

export const CODE_TO_LANG: Record<string, string> = {
  en: "English",
  ar: "Arabic",
  de: "German",
  nl: "Dutch",
  fr: "French",
  hi: "Hindi",
  id: "Indonesian",
  jp: "Japanese",
  kr: "Korean",
  pl: "Polish",
  sp: "Spanish",
  vi: "Vietnamese",
  zh: "Chinese",
};

export const CODE_TO_ISO: Record<string, string> = {
  en: "en_US",
  ar: "ar_SA",
  de: "de_DE",
  nl: "nl_NL",
  fr: "fr_FR",
  hi: "hi_IN",
  id: "id_ID",
  jp: "ja_JP",
  kr: "ko_KR",
  pl: "pl_PL",
  sp: "es_ES",
  vi: "vi_VN",
  zh: "zh_CN",
};

export const ISO_TO_CURRENCY: Record<string, string> = {
  en_US: "USD",
  ar_SA: "SAR",
  de_DE: "EUR",
  nl_NL: "EUR",
  fr_FR: "EUR",
  hi_IN: "INR",
  id_ID: "IDR",
  ja_JP: "JPY",
  ko_KR: "KRW",
  pl_PL: "PLN",
  es_ES: "EUR",
  vi_VN: "VND",
  zh_CN: "CNY",
};
