import React from "react";
import "./WatizenVerify.css";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

export const VerifyOtp = () => {
  return (
    <section className="verify-otp">
      <div className="verify-otp-inner">
        <div className="c-watizen-registration_header">
          <Link to={"/watizenPanel"} style={{ color: "black" }}>
            <ArrowLeftOutlined />
          </Link>
          <label> Watizen Panel </label>
        </div>

        <div className="verify-otp-actual">
          <p className="otp-p">Enter OTP</p>

          <p className="otp-desc">We have sent an OTP to your Email</p>

          <div className="otp-inputs">
            {Array.from({ length: 6 }, (_, index) => (
              <input
                key={index}
                type="text"
                maxLength={1}
                className="otp-input-box"
              />
            ))}
          </div>

          <p className="resend">Resend OTP?</p>

          <div className="reg-btn">
            <Link to="/login">
              <button className="register-btn-">Continue</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
