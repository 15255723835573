import { UserOutlined } from "@ant-design/icons";
import { Card, Input, Button, Alert } from "antd";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { store } from "../../store/index.store";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import "./LogIn.scss";
import { toast } from "react-toastify";

export const Register = observer(() => {
  const navigator = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (!window.isPiAuth) {
      window.location.href = "pi://watugot.net/login";
    }
  }, []);

  const [error, setError] = useState("");

  const { authStore } = store;

  const { isLoggedIn, form } = authStore;

  const nextStep = () => {
    if (form.password !== form.passwordConfirmation)
      setError("Passwords do not match");
    else if (form.password.length < 8)
      setError("Password must be at least 8 characters long");
    else if (form.name.length < 3)
      setError("Name must be at least 3 characters long");
    else if (form.email.length < 3)
      setError("Email must be at least 3 characters long");
    else if (!form.email.includes("@") || !form.email.includes("."))
      setError("Email must be valid");
    else navigator("/verifyAccount");
  };

  if (isLoggedIn) navigator("/");

  return (
    <Card className="c-login__login__background">
      <label className="c-login__login-page_login-label">
        {t("login.register")}{" "}
      </label>
      {error && (
        <>
          <br />
          {error ? <Alert message={error} type="error" /> : <br />}
        </>
      )}
      <label className="c-login__login-page_email-address" htmlFor="name">
        {t("login.fullname")}
      </label>
      <Input
        value={form.name}
        onChange={(e) => {
          const value = e.target.value;

          const rex = /^[a-zA-Z0-9 ]+$/;

          if (value.length > 20) return;
          if (value.length === 0) return authStore.setForm("name", value);

          if (rex.test(value)) {
            authStore.setForm("name", value);
          } else toast.error("Invalid character");
        }}
        id="name"
        className="c-login__login-page_email-address-input"
        placeholder="John Doe"
        suffix={<UserOutlined />}
      />
      <label
        className="c-login__login-page_register-email-address"
        htmlFor="email"
      >
        {t("login.email")}
      </label>
      <Input
        value={form.email}
        onChange={(e) => {
          authStore.setForm("email", e.target.value);
        }}
        id="email"
        className="c-login__login-page_email-address-input"
        placeholder="JohnDoe@reallyimportantcompany.com"
        suffix={<UserOutlined />}
      />
      <label className="c-login__login-page_password-label" htmlFor="password">
        {t("login.password")}
      </label>
      <Input.Password
        onChange={(e) => {
          authStore.setForm("password", e.target.value);
        }}
        value={form.password}
        id="password"
        className="c-login__login-page_password-input"
        placeholder="*****************"
      />
      <label
        className="c-login__login-page_password-label"
        htmlFor="passConfirmation"
      >
        {t("login.confirm-password")}
      </label>
      <Input.Password
        onChange={(e) => {
          authStore.setForm("passwordConfirmation", e.target.value);
        }}
        value={form.passwordConfirmation}
        id="passConfirmation"
        className="c-login__login-page_password-input"
        placeholder="*****************"
      />

      {/* This is for mobile devices only, need to implement later */}
      <div>
        <Button
          type="primary"
          shape="round"
          className="c-login__login-button"
          onClick={nextStep}
        >
          {t("login.continue")}
        </Button>
      </div>
    </Card>
  );
});
