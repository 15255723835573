import { makeAutoObservable } from "mobx";

class LoaderStore {
  isLoadingCategories = false;

  /** Product Page */
  isLoadingProductPage = false;

  /** Auth */
  isLoggingIn = false;

  isLoadingYouWillLike = false;

  isLoadingTakeALook = false;

  isLoadingProfilePicture = false;

  isLoadingWishes = false;
  isEditingWishes = false;

  isLoadingCurrentLocation = true;

  constructor() {
    makeAutoObservable(this);
  }

  setIsLoading = (loaderVar: keyof LoaderStore, value: boolean) => {
    // @ts-ignore
    if (loaderVar.slice(0, 2) === "is") this[loaderVar] = value;
  };
}

export { LoaderStore };
