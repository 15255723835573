import { config, server } from "..";

export const getWishlist = async () => {
  return await server.get(`/wishlist`, config);
};

export const addToWishlist = async (productId: string) => {
  return await server.post(`/wishlist?id=${productId}`, {}, config);
};

export const deleteFromWishlist = async (productId: string) => {
  return await server.delete(`/wishlist?id=${productId}`, config);
};
