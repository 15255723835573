import { config, server } from "..";

interface Feedback {
  name: string;
  email: string;
  department: "sales" | "customer support";
  description: string;
}

export const sendFeedback = async (feedback: Feedback) => {
  return server.post("/feedback", feedback, config);
};
