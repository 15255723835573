import { makeAutoObservable } from "mobx";
import { getCategories } from "../api/categories";

interface Category {
  _id: string;
  name: string;
  parentID: string | null;
}

class UIStore {
  loaderStore: any;
  isUserOverlayOpen = false;
  displayAdvanceSearchButton = true;
  displayContinueButtonInProfilePage = true;
  displaySaveChangesButtonInProfilePage = false;
  displaySearchButton = false;
  drawerHeight = "160px";
  location: any;
  itemAdded = false;
  open = false;
  searchFormKey = 0;
  showButton = true;
  showLanguage = true;
  showRegistration = false;
  showSearch = false;
  showProfile = false;
  showFields = true;
  showMyItems = false;
  profileButtonText = "Continue";
  verificationHeadline = "Verify Account";
  verificationMessage =
    "We have sent an email to hellojohn@gmail.com. Please check your email";
  paymentStartedModal = false;

  categories: Category[] | null = null;

  storeProducts: { title: string }[] = [];
  selectedProducts: "Delivery" | "Pick-up" = "Delivery";

  searchValue: string = "";

  selectedCategory: "new" | "used" = "new";

  localMessages: any[] = [];

  constructor(loaderStore: any) {
    makeAutoObservable(this);
    this.loaderStore = loaderStore;
  }

  addLocalMessage = (message: any) => {
    const recentMessages = this.localMessages.filter(
      (msg) => msg.date > new Date().getTime() - 1000 * 60 * 5
    );

    this.localMessages = [
      ...recentMessages,
      { ...message, date: new Date().getTime() },
    ];
  };

  setSelectedCategory = (value: "new" | "used") => {
    this.selectedCategory = value;
  };

  addItem = (value: boolean) => {
    this.itemAdded = value;
  };

  setPaymentStartedModal = (value: boolean) => {
    this.paymentStartedModal = value;
  };

  setDisplayAdvanceSearchButton = (value: boolean) => {
    this.displayAdvanceSearchButton = value;
  };

  setDisplayContinueButtonInProfilePage = (value: boolean) => {
    this.displayContinueButtonInProfilePage = value;
  };

  setDisplaySaveChangesButtonInProfilePage = (value: boolean) => {
    this.displaySaveChangesButtonInProfilePage = value;
  };

  setDisplaySearchButton = (value: boolean) => {
    this.displaySearchButton = value;
  };

  setDrawerHeight = (value: string) => {
    this.drawerHeight = value;
  };

  setIsUserOverlayOpen(isUserOverlayOpen: boolean) {
    this.isUserOverlayOpen = isUserOverlayOpen;
  }
  setLocation = (value: String) => {
    this.location = value;
  };

  setShowLanguage = (value: boolean) => {
    this.showLanguage = value;
  };

  setShowSearch = (value: boolean) => {
    this.showSearch = value;
  };

  setCategories = (value: Category[]) => {
    this.categories = value;
  };

  setOpen = (value: boolean) => {
    this.open = value;
  };

  setShowRegistration = (value: boolean) => {
    this.showRegistration = value;
  };

  setSearchFormKey = (value: any) => {
    this.searchFormKey = value;
  };

  setSelectedProducts = (value: "Delivery" | "Pick-up") => {
    this.selectedProducts = value;
  };

  setShowProfile = (value: boolean) => {
    this.showProfile = value;
  };

  setShowFields = (value: boolean) => {
    this.showFields = value;
  };

  setProfileButtonText = (value: string) => {
    this.profileButtonText = value;
  };

  setShowButton = (value: boolean) => {
    this.showButton = value;
  };

  setShowMyItems = (value: boolean) => {
    this.showMyItems = value;
  };

  setVerificationHeadline = (value: string) => {
    this.verificationHeadline = value;
  };

  setVerificationMessage = (value: string) => {
    this.verificationMessage = value;
  };

  loadCategories = async () => {
    if (this.categories) return;
    this.loaderStore.setIsLoading("isLoadingCategories", true);

    const { data } = await getCategories();
    this.setCategories(data?.response);

    try {
    } catch (error) {
      console.log(error);
    } finally {
      this.loaderStore.setIsLoading("isLoadingCategories", false);
    }
  };

  setSearchValue = (e: any) => {
    this.searchValue = e;
  };
}

export { UIStore };
