import { UserOutlined } from "@ant-design/icons";
import { Alert, Input, Button } from "antd";
import { useState } from "react";
//import { useNavigate } from "react-router-dom";
import { store } from "../../store/index.store";
import "./ResetPassword.scss";
import { changePassword, sendOtp } from "../../api/authorisation";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

export const ResetPassword = observer(() => {
  //   const navigate = useNavigate();
  const [submitedEmail, setSubmitedEmail] = useState(false);

  const { authStore } = store;

  const { form } = authStore;

  const { email, otp, password, passwordConfirmation } = form;

  const navi = useNavigate();

  const [error, setError] = useState("");

  const requestOtp = async () => {
    if (!email) return setError("Please enter your email");
    try {
      await sendOtp({ email });
      setSubmitedEmail(true);
    } catch (e: any) {
      setError(
        e?.response?.data?.data?.response ||
          e?.message ||
          "An error occurred, please try again"
      );
    }
  };

  const resetPassword = async () => {
    if (!email || !otp || !password || !passwordConfirmation)
      return setError("Please fill all the fields");

    if (password !== passwordConfirmation)
      return setError("Passwords do not match");

    try {
      await changePassword({ email, otp, password });
      navi("/login");
      setSubmitedEmail(true);
    } catch (e: any) {
      console.error(e);
      setError(
        e?.response?.data?.data?.response ||
          e?.response?.data?.data?.name ||
          e?.message ||
          "An error occurred, please try again"
      );
    }
  };

  return (
    <div className="p-reset-password">
      <div className="p-reset-password__Verify">
        <div className="p-reset-password__Verify-label">Verify your email</div>

        {error && (
          <>
            <br />
            <Alert message={error} type="error" />
            <br />
          </>
        )}

        <div className="p-reset-password__Verify-label-message">
          Here you can reset your account password. Please enter your email
        </div>

        <div className="p-reset-password__Verify-form">
          <Input
            value={form.email}
            onChange={(e) => {
              authStore.setForm("email", e.target.value);
            }}
            id="email"
            className="p-reset-password__Verify-label-field"
            placeholder="
          Email address"
            suffix={<UserOutlined />}
          />

          {submitedEmail && (
            <>
              <Input
                value={form.otp}
                onChange={(e) => {
                  authStore.setForm("otp", e.target.value);
                }}
                id="otp-input"
                className="p-reset-password__Verify-label-field"
                placeholder="Enter OTP code"
                inputMode="numeric"
              />
              <p>
                Reminder: Also check your spam folder for the OTP email.
                <br /> If you still can't find it, please request a new OTP.
              </p>
              <Input
                value={form.password}
                onChange={(e) => {
                  authStore.setForm("password", e.target.value);
                }}
                id="password"
                className="p-reset-password__Verify-label-field"
                placeholder="Enter your desired password"
                inputMode="text"
              />
              <Input
                value={form.passwordConfirmation}
                onChange={(e) => {
                  authStore.setForm("passwordConfirmation", e.target.value);
                }}
                id="passwordConfirmation"
                className="p-reset-password__Verify-label-field"
                placeholder="Enter your desired password again"
                inputMode="text"
              />
            </>
          )}
          <Button
            className="p-reset-password__Verify-label-button"
            type="primary"
            onClick={submitedEmail ? resetPassword : requestOtp}
          >
            <p>{submitedEmail ? "Submit OTP" : "Request Password reset"}</p>
          </Button>
        </div>
      </div>
    </div>
  );
});
