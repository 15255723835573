import axios from "axios";

export const trackEvent = ({
  email,
  path,
  uid,
}: {
  email: string;
  path: string;
  uid: string;
}) => {
  axios.post(
    "https://nehjv4ynd6ikq7vmxd3rv5jj6i0mukxy.lambda-url.us-east-1.on.aws/",
    {
      email,
      path,
      uid,
    }
  );
};
