import defImg from "../../assets/images/not-found.jpg";

export const getImageURL = (imageId: string, lq: boolean = true) => {
  const baseURL = process.env.REACT_APP_IMAGE_BUCKET;
  const quality = lq ? "hd_" : "";
  if (!imageId) {
    return defImg;
  }
  return `${baseURL}${quality}${imageId}.webp`;
};
