import { useEffect, useRef, useState } from "react";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { deleteProduct, getMyProducts } from "../../api/products";
import { productMapper } from "../../mappers/productMappers";
import { PathBasedBreadcrumb } from "../../components/PathBasedBreadcrumb/PathBasedBreadcrumb";
import { IProduct } from "../../common/models";
import { Modal, Spin } from "antd";
import { EditableProduct } from "../../components/EditableProduct/EditableProduct";
import { useNavigate } from "react-router-dom";
import { PaymentReason } from "../Payment/Payment";

export const EditItemsPage = () => {
  const [myItems, setMyItems] = useState<IProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<
    string | null
  >(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  const loadProducts = async () => {
    try {
      setIsLoading(true);
      const productsReq = await getMyProducts(
        currentlyLoadedProducts.current,
        currentlyLoadedProducts.current + 10
      );

      currentlyLoadedProducts.current =
        myItems?.length + productsReq?.productObj.length;

      setMyItems((prods) => [
        ...prods,
        ...productsReq?.productObj.map(productMapper),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadProducts();
  }, []);

  const currentlyLoadedProducts = useRef(0);

  useBottomScrollListener(
    () => {
      loadProducts();
    },
    {
      offset: 0,
      debounce: 200,
      debounceOptions: { leading: true },
      triggerOnNoScroll: false,
    }
  );

  return (
    <div>
      <Modal
        open={!!isDeleteModalVisible}
        onOk={async () => {
          setIsDeleting(true);
          try {
            if (isDeleteModalVisible) await deleteProduct(isDeleteModalVisible);

            setIsDeleteModalVisible(null);

            setMyItems((prods) =>
              prods.filter((product) => product.id !== isDeleteModalVisible)
            );
          } catch (e) {
            console.log(e);
          } finally {
            setIsDeleting(false);
          }
        }}
        onCancel={() => setIsDeleteModalVisible(null)}
      >
        Are you sure you want to delete this item?
      </Modal>
      <PathBasedBreadcrumb />
      <div>
        <Spin spinning={isLoading || isDeleting}>
          {myItems.map((product, i) => {
            return (
              <EditableProduct
                key={product.id + i}
                product={product}
                onDelete={() => setIsDeleteModalVisible(product.id)}
                onEdit={() => {
                  navigate(`/edit-product/${product.id}`);
                }}
                onFiatTax={() => {
                  navigate(
                    `/payment/${PaymentReason.fiat_listing_fee}/${product.id}`
                  );
                }}
              />
            );
          })}
        </Spin>
      </div>
    </div>
  );
};
