import SearchOutlined from "@ant-design/icons/lib/icons/SearchOutlined";
import { Row, Col, Button, Card } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { Carrousel } from "pages/HomePage/Carrousel/Carrousel";
import { store } from "store/index.store";
import { Product } from "../product/Product";

import "./StoreProducts.scss";

export const StoreProducts = observer(() => {
  const { UIStore } = store;
  const { selectedProducts, setSelectedProducts } = UIStore;
  const products = UIStore.products;

  return (
    <div className="c-store-products">
      <Carrousel />
      <Row className="c-store-products__header-row">
        <Col span={12} key="1">
          <Button
            className={classNames(
              "c-store-products__button",
              "c-store-products__button--left",
              {
                "c-store-products__button--selected":
                  selectedProducts === "Delivery",
                "c-store-products__button--unselected":
                  selectedProducts === "Pick-up",
              }
            )}
            onClick={() => setSelectedProducts("Delivery")}
          >
            Delivery
          </Button>
        </Col>
        <Col span={12} key="2">
          <Button
            className={classNames(
              "c-store-products__button",
              "c-store-products__button--right",
              {
                "c-store-products__button--selected":
                  selectedProducts === "Pick-up",
                "c-store-products__button--unselected":
                  selectedProducts === "Delivery",
              }
            )}
            onClick={() => setSelectedProducts("Pick-up")}
          >
            Pick-up
          </Button>
        </Col>
      </Row>
      {selectedProducts === "Delivery" && (
        <div className="c-store-products__list">
          <div className="c-store-products__list-filter">
            <Row>
              <Button
                shape="circle"
                className="c-store-products_filter-buttons-button"
              >
                All
              </Button>
              <Button
                type="link"
                className="c-store-products_filter-buttons-button"
              >
                T-shirt
              </Button>
              <Button
                type="link"
                className="c-store-products_filter-buttons-button"
              >
                Apple
              </Button>
            </Row>
            <SearchOutlined className="c-store-products__list-search" />
          </div>
          <div className="c-take-a-look__list">
            {products.map((product) => {
              return (
                <Product mode="vertical" onAddToCart={() => {}} {...product} />
              );
            })}
          </div>
        </div>
      )}
      {selectedProducts === "Pick-up" && (
        <div className="c-store-products__list">
          <div className="c-store-products__list-address">
            <Card>
              <Row className="c-store-products__list-address-label">
                <label>Address</label>
              </Row>
              <Row className="c-store-products__list-address-label-add">
                <label>
                  Lorem ipsum dolor sit amet.
                </label>
              </Row>
            </Card>
          </div>
          <div className="c-store-products__list-filter">
            <Row>
              <Button
                shape="circle"
                className="c-store-products_filter-buttons-button"
              >
                All
              </Button>
              <Button
                type="link"
                className="c-store-products_filter-buttons-button"
              >
                T-shirt
              </Button>
              <Button
                type="link"
                className="c-store-products_filter-buttons-button"
              >
                Apple
              </Button>
            </Row>
            <SearchOutlined className="c-store-products__list-search" />
          </div>
          {products.map((product) => {
            return (
              <Product mode="vertical" onAddToCart={() => {}} {...product} />
            );
          })}
        </div>
      )}
    </div>
  );
});
