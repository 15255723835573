import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { List } from "antd";
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom";

import "./ManageOrders.scss";


 export const ManageOrders = observer(() => {

    const data = [
        {title: 'Verify order ID',to:""},
        {title:'Open orders', to: ""},
        {title: 'Completed orders',to:""},
        {title:'Availed items history', to: ""}];

    return(
            <div className="c-manage-orders">
                <div className="c-manage-orders-header">
                    <Link to={"/storePanelList"}>
                        <ArrowLeftOutlined style={{color:"black"}} />
                    </Link>
                    <label> Manage orders </label>
                </div>
                <List itemLayout="horizontal" dataSource={data} className="c-store-panel_list"
                    renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta title={<Link to={item.to}>{item.title}</Link>} />
                    <ArrowRightOutlined />
                </List.Item>
            )} />
            </div>
    )

 })