import { config, server } from "..";

export const uploadImage = (
  file: FormData,
  newConfig: { headers: { [key: string]: string } }
) => {
  return server.post("/upload-image", file, {
    headers: { ...config.headers, ...newConfig.headers },
  });
};
