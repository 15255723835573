import { observer } from "mobx-react-lite";
import { store } from "../../store/index.store";
import userPic from "../../assets/images/Exampleavatar.png";
import picUpload from "../../assets/images/pic-upload.png";
import { uploadImage } from "../../api/image";
import { getImageURL } from "../../common/utils/imageURLgetter";

import "./ProfileImage.scss";
import { Spin } from "antd";

export const ProfileImage = observer(() => {
  const { authStore, loaderStore } = store;
  const { isLoggedIn } = authStore;

  const selectNUploadProfilePic = () => {
    if (!isLoggedIn) return;

    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.click();

    input.onchange = async (e) => {
      const file = (e.target as HTMLInputElement).files![0];

      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      if (file) {
        const formData = new FormData();
        formData.append("image", file);
        try {
          const { data } = await uploadImage(formData, config);
          if (data?.data) {
            const picid = new URL(data.data).pathname
              .replace("/", "")
              .split(".")[0];

            authStore.updateProfilePic(picid);
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
  };

  return (
    <div
      onClick={selectNUploadProfilePic}
      className={`${isLoggedIn && "clickable"} c-profile-image`}
    >
      {loaderStore.isLoadingProfilePicture ? (
        <Spin spinning={true} />
      ) : (
        <img
          src={
            authStore.userProfile.profileImage
              ? getImageURL(authStore.userProfile.profileImage, true)
              : userPic
          }
          alt="user profile pic"
          width="100px"
          height="100px"
          className="c-profile-image__image"
        />
      )}

      {isLoggedIn && (
        <img
          src={picUpload}
          alt="pic upload icon"
          className="c-profile-image__upload"
        />
      )}
    </div>
  );
});
