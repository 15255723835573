import { ArrowRightOutlined } from "@ant-design/icons";
import { List } from "antd";
import { Link } from "react-router-dom";
import "./MyItems.scss";
import { useTranslation } from "react-i18next";
import { PathBasedBreadcrumb } from "../PathBasedBreadcrumb/PathBasedBreadcrumb";

export const MyItems = () => {
  const { t } = useTranslation();

  const data = [
    { title: t("myItems.wishlist"), to: "wishlist" },
    { title: t("myItems.post-item"), to: "/post-product" },
    { title: t("myItems.post-service"), to: "my-posted-items" },
  ];

  return (
    <div className="c-my-items">
      <div className="c-my-items-header">
        <PathBasedBreadcrumb />
      </div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="c-store-panel_list"
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta title={<Link to={item.to}>{item.title}</Link>} />
            <ArrowRightOutlined />
          </List.Item>
        )}
      />
    </div>
  );
};
