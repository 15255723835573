// libraries import
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { store } from "../../store/index.store";

// styles imports
import "./SlidingOverlay.scss";
import { notification, Row, Spin, Drawer, Modal } from "antd";
import {
  ArrowRightOutlined,
  CloseOutlined,
  FieldBinaryOutlined,
} from "@ant-design/icons";

// page reference imports
import watizen from "../../assets/images/watizen.png";
import userProfileImg from "../../assets/images/userProfile.png";
import myItems from "../../assets/images/myItems.png";
import notifications from "../../assets/images/notifications.png";
import messages from "../../assets/images/messages.png";
import settings from "../../assets/images/settings.png";
import { useTranslation } from "react-i18next";
import { ProfileImage } from "../UploadableProfileImage/ProfileImage";

export const SlidingOverlay = observer(() => {
  const { UIStore, loaderStore, authStore } = store;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoggedIn, logOut, userProfile } = authStore;

  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const openNotification = () => {
    notification.open({
      message: t("sidebar.notifications"),
      description: t("sidebar.no_new_notif"),
    });
  };

  const notAvable = () => {
    notification.open({
      message: t("sidebar.not_available"),
      description: t("sidebar.feat_not_available"),
    });
  };

  const closeDrawer = () => {
    UIStore.setIsUserOverlayOpen(!UIStore.isUserOverlayOpen);
  };
  useEffect(() => UIStore.setShowProfile(UIStore.showProfile));

  return (
    <>
      {UIStore.isUserOverlayOpen && (
        <>
          <Modal
            open={isLogoutModalOpen}
            closable={false}
            onCancel={() => setIsLogoutModalOpen(false)}
            onOk={() => logOut()}
          >
            Do you really want to logout?
          </Modal>
          <Drawer
            open={UIStore.isUserOverlayOpen}
            closable={false}
            onClose={() => UIStore.setIsUserOverlayOpen(false)}
            placement="left"
            width={300}
            bodyStyle={{
              padding: "0px",
              overflowX: "hidden",
              scrollbarWidth: "none",
            }}
          >
            <div className="c-sliding-overlay__card">
              <div className="c-sliding-overlay__card-design">
                <CloseOutlined
                  className="c-sliding-overlay__card-design-closebutton"
                  onClick={() =>
                    UIStore.setIsUserOverlayOpen(!UIStore.isUserOverlayOpen)
                  }
                />
                <ProfileImage />
                {isLoggedIn && (
                  <div className="c-option-drawer-username">
                    <label>
                      {/**@ts-ignore */}
                      {userProfile.fullName || t("sidebar.loading")}
                    </label>
                  </div>
                )}
                {/* <div className="c-option-drawer-userjointime">
                  <label>Joined 2 years ago</label>
                </div> */}
              </div>
              {isLoggedIn ? (
                <Spin
                  className="c-option-drawer-page"
                  spinning={loaderStore.isLoadingCategories}
                >
                  <hr className="c-option-drawer-page-hr" />
                  <div className="c-option-drawer-page__list">
                    <Link to="/userProfile" onClick={closeDrawer}>
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={userProfileImg}
                            alt="user profile"
                            className="c-option-drawer-page__list-item_img"
                          />
                          <div
                            className="c-option-drawer-page__list-item-text"
                            onClick={() =>
                              UIStore.setShowProfile(!UIStore.showProfile)
                            }
                          >
                            {t("sidebar.profile-settings")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link to="/myItems" onClick={closeDrawer}>
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={myItems}
                            alt="my items"
                            className="c-option-drawer-page__list-item_img"
                          />
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.my-items")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link to="#" onClick={openNotification}>
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={notifications}
                            alt="notification"
                            className="c-option-drawer-page__list-item_img"
                          />
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.notifications")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link to="/chats" onClick={closeDrawer}>
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={messages}
                            alt="message"
                            className="c-option-drawer-page__list-item_img"
                          />
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.messages")}
                            <FieldBinaryOutlined />
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link /*to="/watizenPanel"*/ to="#" onClick={notAvable}>
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={watizen}
                            alt="watizen panel"
                            className="c-option-drawer-page__list-item__watizen-image"
                          />
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.w-panel")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link
                      /*to="/storeLogin" onClick={closeDrawer} */ to="#"
                      onClick={notAvable}
                    >
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={watizen}
                            alt="store panel"
                            className="c-option-drawer-page__list-item__watizen-image"
                          />
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.s-panel")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link to="/piActivity" onClick={closeDrawer}>
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={watizen}
                            alt="pi activity"
                            className="c-option-drawer-page__list-item__watizen-image"
                          />
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.pi_activity")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link to="settings" onClick={closeDrawer}>
                      <div className="c-option-drawer-page__list-item">
                        <Row className="c-option-drawer-page__list-item_row">
                          <img
                            src={settings}
                            alt="setting"
                            className="c-option-drawer-page__list-item_img"
                          />
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.app_settings")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link to="/faq" onClick={closeDrawer}>
                      <div className="c-option-drawer-page__list-item-lower-panel">
                        <Row className="c-option-drawer-page__list-item_row">
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.faq")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>
                    <Link to="/feedback" onClick={closeDrawer}>
                      <div className="c-option-drawer-page__list-item-lower-panel">
                        <Row className="c-option-drawer-page__list-item_row">
                          <div className="c-option-drawer-page__list-item-text">
                            {t("sidebar.feedback")}
                          </div>
                          <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                        </Row>
                      </div>
                    </Link>

                    <div
                      className="c-option-drawer-page__list-item-lower-panel clickable"
                      onClick={() => setIsLogoutModalOpen(true)}
                    >
                      <Row className="c-option-drawer-page__list-item_row">
                        <div className="c-option-drawer-page__list-item-text">
                          {t("sidebar.log_out")}
                        </div>
                        <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                      </Row>
                    </div>
                  </div>
                </Spin>
              ) : (
                <>
                  <div
                    className="c-option-drawer-page__list-item-lower-panel clickable"
                    onClick={() => {
                      closeDrawer();
                      navigate("/login");
                    }}
                  >
                    <Row className="c-option-drawer-page__list-item_row">
                      <div className="c-option-drawer-page__list-item-text">
                        {t("sidebar.login")}
                      </div>
                      <ArrowRightOutlined className="c-option-drawer-page__list-item-arrow" />
                    </Row>
                  </div>
                </>
              )}
              <div className="c-sliding-overlay__copyright">
                <Row className="c-sliding-overlay__copyright_row">
                  <h3>© Watugot</h3>
                </Row>
              </div>

              <div className="c-sliding-overlay__terms-button">
                <a
                  href="/Watugot-Privacy_Policy.docx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("sidebar.privacyPolicy")}
                </a>
                &nbsp;
                {t("sidebar.and")}&nbsp;
                <a
                  href="/Watugot-Terms_and_Conditions.docx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("sidebar.termsAndConditions")}
                </a>
              </div>
            </div>
          </Drawer>
        </>
      )}
    </>
  );
});
