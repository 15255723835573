import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button, InputNumber, Space } from "antd";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";

import "./Verification.scss";

export const Verification = observer(() => {
  return (
    <div className="c-verification">
      <div className="c-verification__header-navigation">
        <ArrowLeftOutlined />
        <label style={{ margin: "20px", font: "20px", fontWeight: 500 }}>
          Verification
        </label>
      </div>
      <div className="c-verification__header">
        <label className="c-verification__header-label">Enter OTP</label>
      </div>
      <div className="c-verification__message">
        <label className="c-verification__message-label">
          We have sent you an otp to your phone number.
        </label>
      </div>
      <Space className="c-verification__otp">
        <InputNumber
          className="c-verification__input"
          maxLength={1}
          controls={false}
        />
        <InputNumber
          className="c-verification__input"
          maxLength={1}
          controls={false}
        />
        <InputNumber
          className="c-verification__input"
          maxLength={1}
          controls={false}
        />
        <InputNumber
          className="c-verification__input"
          maxLength={1}
          controls={false}
        />
        <InputNumber
          className="c-verification__input"
          maxLength={1}
          controls={false}
        />
        <InputNumber
          className="c-verification__input"
          maxLength={1}
          controls={false}
        />
      </Space>
      <div className="c-verification__link">
        <Button type="text" style={{ color: "#159a8c" }}>
          Resend OTP
        </Button>
      </div>
      <div className="c-verification__footer">
        <Link to={"/verifyPhone"}>
          <Button
            type="primary"
            shape="round"
            className="c-verification__footer-button"
          >
            Continue
          </Button>
        </Link>
      </div>
    </div>
  );
});
