import { ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { Input, Button } from "antd";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";


import "./PhoneNumber.scss";

export const PhoneNumber = observer(() => {

  return(
    <div className="c-phone-number">
        <div className="c-phone-number__header">
            <ArrowLeftOutlined />
            <label style={{margin:"20px", font:"20px", fontWeight:500}}>Phone Number</label>
        </div>
        <div className="c-phone-number__header">
            <label>Phone Number</label>
        </div>
        <div>
            <Input suffix={<UserOutlined />} className="c-phone-number__header-phone-input" prefix={"+1"}/>
       </div>
       <div className="c-phone-number__footer">
        <Link to={"/verification"}>
          <Button type="primary" shape="round" className="c-phone-number__footer-button"> Send OTP</Button>
        </Link>
       </div>
    </div>
  );
});
