import { ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";

export const PathBasedBreadcrumb = ({
  customEnd,
  universalRedirect,
}: {
  customEnd?: string;
  universalRedirect?: string;
}) => {
  const location = useLocation();
  const { t } = useTranslation();

  const PagesDictionary: Record<string, string> = {
    wishlist: t("breadcrumb_wishlist"),
    myItems: t("breadcrumb_my_items"),
    "my-posted-items": t("breadcrumb_my_posted"),
    search: t("breadcrumb_search"),
    feedback: t("breadcrumb_feedback"),
    "items-activity": t("breadcrumb_item_activity"),
    piActivity: t("breadcrumb_pi_activity"),
    user: t("breadcrumb_user"),
    products: t("breadcrumb_products"),
    settings: t("breadcrumb_settings"),
    location: t("breadcrumb_Location"),
    faq: t("breadcrumb_faqs"),
    userProfile: t("breadcrumb_user_profile"),
    chats: t("breadcrumb_chats"),
    "post-product": t("breadcrumb_post_item"),
    "edit-product": t("breadcrumb_edit_item"),
  };

  const paths = location.pathname
    .split("/")
    .filter((path) => path)
    .map((path) => (PagesDictionary[path] ? PagesDictionary[path] : path));

  const lastItemPath = location.pathname.split("/").slice(0, -1).join("/");

  return (
    <div style={{ marginLeft: "10px" }}>
      <Link to={universalRedirect || lastItemPath || "/home-page"}>
        <ArrowLeftOutlined style={{ color: "black" }} />
      </Link>
      {paths.map((path, index) => (
        <span key={index} style={{ marginLeft: "10px" }}>
          {index === paths.length - 1 && customEnd ? customEnd : path}{" "}
          {index !== paths.length - 1 ? " / " : ""}
        </span>
      ))}
    </div>
  );
};
