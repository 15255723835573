import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { List, Row } from "antd";
import Card from "antd/lib/card/Card";
import { Link } from "react-router-dom";
import "./WatizenPanel.scss";
import { useTranslation } from "react-i18next";

export const WatizenPanel = () => {
  const {t} = useTranslation();
  const data = [
    { title: t("watizenPanel.becomeUser"), to: "/watizenRegistration" },
    { title: t("watizenPanel.howItWorks"), to: "" },
  ];

  return (
    <div className="c-watizen-panel">
      <div className="c-watizen-panel-header">
        <Link to={"/home-page"}>
          <ArrowLeftOutlined style={{ color: "black" }} />
        </Link>
        <label>{t("watizenPanel.watizenPanel")} </label>
      </div>
      <div className="c-watizen-panel-warning">
        <Card className="c-watizen-panel-warning-card">
          <Row>
            <EyeOutlined className="c-watizen-panel-warning-eye" />
            <label className="c-watizen-panel-warning-label">
              {t("watizenPanel.noRegist")}
            </label>
          </Row>
          <Row>
            <label className="c-watizen-panel-warning-warning">
              {t("watizenPanel.warningHere")}

            </label>
          </Row>
        </Card>
      </div>
      <List
        itemLayout="horizontal"
        dataSource={data}
        className="c-store-panel_list"
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta title={<Link to={item.to}>{item.title}</Link>} />
            <ArrowRightOutlined />
          </List.Item>
        )}
      />
    </div>
  );
};
