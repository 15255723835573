import React from "react";
import "./ts.css";
import backgroundImage from "../../assets/images/frame_background.png";
import { FaRegStar } from "react-icons/fa";
import { useTranslation } from "react-i18next";

export const Transactionsuccess = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="trans-success">
        <div className="trans-inner">
          <div className="trans-img">
            <img src={backgroundImage} alt="background" className="image" />
          </div>
          <div className="delivered-div">
            <h2 className="del-h2">{t("transaction.delivered")}</h2>
            {/* <p className="items-p">2 Items for 3 PI</p> */}
            <p className="rate-p"> {t("transaction.delivered")}</p>

            <div className="stars">
              <div className="star-rate">
                <FaRegStar className="star-icon" />
              </div>
              <div className="star-rate">
                <FaRegStar className="star-icon" />
              </div>
              <div className="star-rate">
                <FaRegStar className="star-icon" />
              </div>
              <div className="star-rate">
                <FaRegStar className="star-icon" />
              </div>
              <div className="star-rate">
                <FaRegStar className="star-icon" />
              </div>
            </div>

            <p className="improve">
            {t("transaction.help")}{" "}
              <span className="lf">{t("transaction.feedback")}</span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
