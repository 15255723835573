import { makeAutoObservable } from "mobx";
import { IProduct } from "../common/models";
import { getRandomProducts } from "../api/products";
import { productMapper } from "../mappers/productMappers";

class HomePageStore {
  youWillLikeItems: IProduct[] = [];
  takeALookItems: IProduct[] = [];
  loaderStore: any;

  constructor(loaderStore: any) {
    makeAutoObservable(this);
    this.loaderStore = loaderStore;
  }

  fetchYouWillLikeItems = async (location: [string, string]) => {
    this.loaderStore.setIsLoading("isLoadingYouWillLike", true);
    try {
      const data = await getRandomProducts(0, 4, location);
      const products = data?.productObj || [];

      this.youWillLikeItems = products?.map(productMapper);
    } catch (e) {
      console.log(e);
    } finally {
      this.loaderStore.setIsLoading("isLoadingYouWillLike", false);
    }
  };

  fetchTakeALookItems = async (location: [string, string]) => {
    this.loaderStore.setIsLoading("isLoadingTakeALook", true);
    try {
      const data = await getRandomProducts(1, 20, location);
      const products = data?.productObj || [];

      this.takeALookItems = products?.map(productMapper);
    } catch (e) {
      console.log(e);
    } finally {
      this.loaderStore.setIsLoading("isLoadingTakeALook", false);
    }
  };
}

export { HomePageStore };
