import type { APIProduct } from "~/api/products";
import { IProduct } from "~/common/models";

export const productMapper = (product: APIProduct): IProduct => {
  // const links = product?.image ? product.image?.split(",") : [];
  return {
    id: product?._id || "",
    name: product?.title || "",
    price: product?.price || -1,
    price_pi: product?.price_pi || -1,
    price_fiat: product?.price_fiat || -1,
    paymentMode: product?.paymentMode || "pi",
    description: product?.description || "",
    images: product?.images || [],
    condition: product?.condition === "new" ? "new" : "used",
    userId: product?.userId || "",
    coordinates: product?.location?.coordinates || [0, 0],
    fiatCurrency: product?.fiatCurrency || "USD",
    status: product?.status || "active",
  };
};
