
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "./WatizenCode.scss";

export const WatizenCode = () => {
    return(
        <div className="c-watizen-code">
            <div className="c-watizen-code-header">
                <Link to={"/watizenList"}>
                    <ArrowLeftOutlined style={{color:"black"}} />
                </Link>
                <label> Watizen Code / Watizen Code </label>
            </div>
            <div className="c-watizen-code-info">
                <label className="c-watizen-code-info-label">Your watizen code is the same as your pi username, your watizen code is</label>
            </div>
            <div className="c-watizen-code-code">
                <label className="c-watizen-code-code-label">ORD-1562792780452</label>
            </div>
        </div>
    )
}