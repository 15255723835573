import { makeAutoObservable } from "mobx";

interface Product {
  id: string;
  name: string;
  imageSrc: string;
  description: string;
  price: string;
  rating: string;
}

class ProductPageStore {
  loaderStore: any;
  product: Product | null = null;

  constructor(loaderStore: any) {
    makeAutoObservable(this);
    this.loaderStore = loaderStore;
  }

  loadProductPage = (id: string) => {
    if (this.product && this.product.id !== id) this.clearProduct();

    this.loaderStore.setIsLoadingProductPage(true);
    //load stuff with axios

    //

    //this.setProduct() <- add axios result
    this.loaderStore.setIsLoadingProductPage(true);
  };

  getProduct = () => {
    if (!this.product || !this.product.id)
      return {
        id: "",
        name: "",
        imageSrc: "",
        description: "",
        price: "",
        rating: "",
      };

    return this.product;
  };

  buyProduct = () => {
    if (!this.product || !this.product.id)
      return { succes: false, error: "No product id" };
    //handle Pi sdk stuff
  };

  clearProduct = () => {
    this.product = null;
  };

  setProduct = (product: Product) => {
    this.product = product;
  };
}

export { ProductPageStore };
