import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { getProductsBy } from "../../api/products";
import { IProduct } from "../../common/models";
import { productMapper } from "../../mappers/productMappers";
import { Product } from "../../components/product/Product";

import "./CategoryPage.scss";
import { Spin } from "antd";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { store } from "../../store/index.store";

export const CategoryPage = observer(() => {
  const [products, setProducts] = useState<IProduct[]>([]); // [APIProduct, APIProduct, ...
  const [isLoading, setIsLoading] = useState(false);
  const { id: categoryId } = useParams();

  const currentlyLoadedProducts = useRef(0);
  const stopLoading = useRef(false);

  const { authStore } = store;

  const loadProducts = async () => {
    try {
      setIsLoading(true);

      if (stopLoading.current) return;

      const productsReq = await getProductsBy(
        currentlyLoadedProducts.current,
        currentlyLoadedProducts.current + 10,
        {
          category: categoryId,
          location: authStore?.location?.map((loc) => loc.toString()),
        }
      );

      currentlyLoadedProducts.current =
        products?.length + productsReq?.productObj.length;

      if (productsReq?.productObj.length < 10) stopLoading.current = true;

      setProducts((prods) => [
        ...prods,
        ...productsReq?.productObj.map(productMapper),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setProducts([]);
    if (authStore.isLoggedIn && !authStore.location) return;
    loadProducts();
  }, [authStore.location, categoryId, authStore.isLoggedIn]);

  useBottomScrollListener(
    () => {
      loadProducts();
    },
    {
      offset: 0,
      debounce: 200,
      debounceOptions: { leading: true },
      triggerOnNoScroll: false,
    }
  );

  function handleAddToCart(productID: string): void {}

  return (
    <div>
      <div className="p-category-page">
        {products.length > 0 || isLoading ? (
          products.map((product, i) => {
            return (
              <Product
                key={product.id + i}
                mode="vertical"
                onAddToCart={handleAddToCart}
                {...product}
              />
            );
          })
        ) : (
          <div className="p-search__items__container-empty">
            <ShoppingCartOutlined className="p-search__items__container-empty-icon" />
            <div>No products found in your area</div>
          </div>
        )}
      </div>

      <Spin spinning={isLoading} />
    </div>
  );
});
