//libraries imports
import {
  BrowserRouter,
  // Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// page reference imports
// import { WatizenLogin } from "./components/SlidingOverlay/WatizenLogin";
import { VerifyOtp } from "./components/SlidingOverlay/WatizenVerify";
// import { WatizenRegistration } from "./components/SlidingOverlay/WatizenRegistration";
// import { AddEditSubCategories } from "components/StorePanel/AddEditSubCategories";
import { WatizenSuccessful } from "./components/SlidingOverlay/WatizenSuccessful";
import { WatizenDashboard } from "./components/SlidingOverlay/WatizenDashboard";
// import { AddEditCatalogue } from "components/StorePanel/AddEditCatalogue";
import { SlidingOverlay } from "components/SlidingOverlay/SlidingOverlay";
import { FeaturedListings } from "components/StorePanel/FeaturedListings";
import { StoreProducts } from "./components/StoreProducts/StoreProducts";
import { Notifications } from "components/SlidingOverlay/Notifications";
import { WatizenCode } from "./components/SlidingOverlay/WatizenCode";
import { WatizenPanel } from "components/SlidingOverlay/WatizenPanel";
import { WatizenList } from "./components/SlidingOverlay/WatizenList";
import { StorePanelList } from "components/StorePanel/StorePanelList";
import { StoreDashboard } from "components/StorePanel/StoreDashboard";
import { AppSettings } from "components/SlidingOverlay/AppSettings";
import { UserProfile } from "components/SlidingOverlay/UserProfile";
import { ManageOrders } from "./components/StorePanel/ManageOrders";
import { LanguageSetting } from "./pages/AuthPage/LanguageSetting";
import { StorePanel } from "components/SlidingOverlay/StorePanel";
//import { PIActivity } from "components/SlidingOverlay/PIActivity";
import { ManageOffers } from "components/StorePanel/ManageOffers";
import { PostItemPage } from "./pages/PostItemPage/PostItemPage";
import { CategoriesPage } from "./pages/CategoriesPage/CategoriesPage";
import { Feedback } from "components/SlidingOverlay/Feedback";
import { StoreLogIn } from "components/StorePanel/StoreLogIn";
import { ProductPage } from "pages/ProductPage/ProductPage";
import { MyItems } from "components/SlidingOverlay/MyItems";
import { StoresPage } from "./pages/StoresPage/StoresPage";
import { ItemsPage } from "./pages/ItemsPage/ItemsPage";
import { FAQ } from "components/SlidingOverlay/FAQ";
//import { Location } from "components/SlidingOverlay/locations";
import { HomePage } from "pages/HomePage/HomePage";
import { Navbar } from "components/Navbar/Navbar";
import { LogIn } from "./pages/AuthPage/LogIn";
import { PhoneNumber } from "./pages/AuthPage/PhoneNumber";
import { Verification } from "./pages/AuthPage/Verification";
import { VerifyAccount } from "./pages/AuthPage/VerifyAccount";
import { VerifyOTP } from "./pages/AuthPage/VerifyOTP";
import { AddAddress } from "./pages/AuthPage/AddAddress";
import { WishlistPage } from "./pages/WishlistPage/WishlistPage";
import { Register } from "./pages/AuthPage/Register";
import { Transactionsuccess } from "./pages/transaction/ts";
import { updateLocation as serverUpdateLocation } from "api/profile";

// styles imports
import "antd/dist/antd.min.css";
import "./antdOverwtites.scss";
import "./App.css";
import { useEffect, useMemo, useState } from "react";
import { store } from "./store/index.store";
// import { PresentationPage } from "./pages/PresentationPage";
import { CategoryPage } from "./pages/CategoryPage/CategoryPage";
import { observer } from "mobx-react-lite";
import { EditItemsPage } from "./pages/EditItemsPage/EditItemsPage";
import { SearchPage } from "./pages/SearchPage/SearchPage";
import { ResetPassword } from "./pages/ResetPassword/ResetPassword";
import { ItemsActivityPage } from "./pages/ItemsActivityPage/ItemsActivityPage";
import Modal from "antd/lib/modal/Modal";
import { Alert, Checkbox, Rate } from "antd";
import { reviewTransaction } from "./api/transactions";
import { UsersProductPage } from "./pages/UsersProductPage/UsersProductPage";
import { ChatPage } from "./pages/ChatPage/ChatPage";
import { io } from "socket.io-client";
import { ChatsPage } from "./pages/ChatsPage/ChatsPage";
import { PathRedirect, TokenLogin } from "./pages/TokenLogin/TokenLogin";
import { useTranslation } from "react-i18next";
import { trackEvent } from "./api/tracking";
import { initLocation } from "./common/utils/locationUtils";
import loadingGif from "assets/images/loading.gif";
import { PaymentPage } from "./pages/Payment/Payment";

const LocationModal = observer(() => {
  const { authStore } = store;
  const { location, isLoggedIn } = authStore;

  return (
    <Modal
      open={isLoggedIn && location === undefined}
      title={"Loading your current location"}
      footer={null}
      closeIcon={" "}
    >
      <img src={loadingGif} alt="loading" style={{ width: "100%" }} />
    </Modal>
  );
});

const App = observer(() => {
  const { authStore, UIStore } = store;
  const { checkIfLoggedIn, loadWishes } = authStore;
  const { t } = useTranslation();

  const [txRatings, setTxRatings] = useState<{ [key: string]: number }>({});

  const [showVersionModal, setShowVersionModal] = useState<boolean | null>(
    null
  );

  const [isMainetModal, setIsMainetModal] = useState(
    localStorage.getItem("isMainet") !== "true"
  );
  const [doNotShowMainetModal, setDoNotShowMainetModal] = useState(false);

  useEffect(() => {
    //connect the status websocket
    if (!authStore.isLoggedIn) return;

    io(process.env.REACT_APP_API_URL!, {
      path: "/online",
      auth: {
        token: localStorage.getItem("token"),
      },

      transports: ["websocket"],
    });

    const chatSocket = io(process.env.REACT_APP_API_URL!, {
      path: `/messaging`,
      auth: {
        token: localStorage.getItem("token"),
      },

      transports: ["websocket"],
    });

    chatSocket.on("newMessage", (data: any) => {
      UIStore.addLocalMessage(data);
    });
  }, [authStore.userProfile.userId]);

  useEffect(() => {
    checkIfLoggedIn()
      .then((res) => {
        if (res) {
          loadWishes();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    authStore.userProfile.unreviewedTransactions?.forEach((transaction) => {
      authStore.loadTxData(transaction);
    });
  }, [authStore.userProfile.unreviewedTransactions]);

  const Protected = observer(({ element: Component }: any) => {
    const navigate = useNavigate();
    const { authStore } = store;
    const { isLoggedIn } = authStore;

    if (!isLoggedIn) {
      navigate("/login");
      return <HomePage />;
    }

    return <Component />;
  });

  const PIOnly = observer(({ element: Component }: any) => {
    const { t } = useTranslation();

    if (!window.isPiAuth && process.env.REACT_APP_ENVIROMENT !== "DEV") {
      return (
        <>
          <Alert type="error" message={t("location_page.error")} />
          <br />
          <a href="pi://watugot.net"> {t("location_page.open_pi")}</a>
          <br />
          <br />
          <p>
            New to Pi Network?
            <br /> No Pi account? No problem!{" "}
            <a href="https://minepi.com/Dust101">Click here</a> to join the Pi
            Network and start mining Pi cryptocurrency on your mobile device.
            Then, download the Pi Browser to access Watugot and unlock a world
            of decentralized trade! <br />
            <a href="https://minepi.com/Dust101">Click here</a> to become a pi
            user!
          </p>
        </>
      );
    }

    return <Component />;
  });

  const PathAnalytics = observer(() => {
    const location = useLocation();
    const { authStore } = store;

    useEffect(() => {
      window.dataLayer.push(
        {
          event: "pageview",
          page: location.pathname,
        },
        "pageview"
      );

      if (!localStorage.getItem("uid"))
        localStorage.setItem("uid", Math.random().toString(36).substring(2));

      trackEvent({
        email: authStore.userProfile.email,
        path: location.pathname,
        uid: localStorage.getItem("uid") || "",
      });
    }, [location]);

    return <></>;
  });

  const isVersionQuerryParam = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get("version");
  }, [window.location.search]);

  useEffect(() => {
    if (
      (isVersionQuerryParam && showVersionModal === null) ||
      showVersionModal
    ) {
      setShowVersionModal(true);
    } else {
      setShowVersionModal(false);
    }
  }, [isVersionQuerryParam, showVersionModal]);

  const shouldReviewTxs = useMemo(() => {
    return authStore.userProfile.unreviewedTransactions?.filter((el) => {
      return !authStore.canceledTxReviews.includes(el);
    });
  }, [
    authStore.userProfile.unreviewedTransactions,
    authStore.canceledTxReviews,
  ]);

  const updateLocation = (location: [number, number, number]) => {
    if (location[0] === 0 && location[1] === 0) return;
    authStore.setLocation(location);
    serverUpdateLocation({
      lat: location[0],
      long: location[1],
    });
  };

  useEffect(() => {
    if (
      authStore.isLoggedIn //&&

      // !authStore.userProfile?.location?.coordinates?.length
    )
      initLocation(authStore.location, updateLocation);
    else authStore.setLocation(undefined);
  }, [authStore.isLoggedIn]);

  const HomepageRedirecter = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/home-page");
    }, []);
    return <></>;
  };

  return (
    <div className="App">
      {authStore.isLoggedIn && <LocationModal />}
      {shouldReviewTxs?.map((txId) => (
        <Modal
          open={true}
          title={t("tx_review_title")}
          key={txId}
          onCancel={() => {
            authStore.cancelTxReview(txId);
          }}
          onOk={() => {
            reviewTransaction(txId, txRatings[txId]);
            authStore.cancelTxReview(txId);
          }}
        >
          <div>
            <p
              dangerouslySetInnerHTML={{
                __html:
                  t("tx_review", {
                    productName:
                      authStore.transactionsData?.[txId]?.productName,
                    date: new Date(
                      authStore.transactionsData?.[txId]?.createdAt
                    ).toDateString(),
                    ammount: authStore.transactionsData?.[txId]?.amount,
                  }) || "",
              }}
            ></p>
            <Rate
              defaultValue={3}
              value={txRatings[txId]}
              onChange={(value) => {
                setTxRatings({ ...txRatings, [txId]: value });
              }}
            />
          </div>
        </Modal>
      ))}
      <Modal
        title={t("mainet_ack_title")}
        open={isMainetModal}
        onCancel={() => {
          setIsMainetModal(false);
          if (doNotShowMainetModal) {
            localStorage.setItem("isMainet", "true");
          }
        }}
        onOk={() => {
          setIsMainetModal(false);
          if (doNotShowMainetModal) {
            localStorage.setItem("isMainet", "true");
          }
        }}
      >
        <p>
          <b>Imp Note:</b> This is a Pi Mainnet app. Access our app with or
          without a Pi Mainnet wallet or migrated Pi coins. Enjoy seamless
          trading in both Pi cryptocurrency and local currencies. Join our
          inclusive community and revolutionize digital commerce!
        </p>
        <Checkbox
          checked={doNotShowMainetModal}
          onChange={(e) => {
            setDoNotShowMainetModal(e.target.checked);
          }}
        >
          {t("not_show_again")}
        </Checkbox>
      </Modal>

      <Modal
        open={UIStore.paymentStartedModal}
        title={t("payment_started_title")}
        onCancel={() => {
          UIStore.setPaymentStartedModal(false);
        }}
        onOk={() => {
          UIStore.setPaymentStartedModal(false);
        }}
      >
        {t("payment_started")}
      </Modal>

      <BrowserRouter>
        <Modal
          open={!!showVersionModal}
          title="Version"
          onCancel={() => {
            setShowVersionModal(false);
          }}
          onOk={() => {
            setShowVersionModal(false);
          }}
        >
          <p>Current version date: {process.env.REACT_APP_BUILD_DATE}</p>
          User agent:{JSON.stringify(window.navigator.userAgent)}
        </Modal>
        {/* < /> */}

        <PathAnalytics />
        <Navbar />
        <SlidingOverlay />
        <Routes>
          <Route path="/home-page" index element={<HomePage />} />
          <Route path="/" element={<HomepageRedirecter />} />
          <Route path="/login" element={<LogIn />} />
          <Route
            path="/login-register"
            element={<PIOnly element={Register} />}
          />
          {/* <Route
            path="/addEditSubCategories"
            element={<AddEditSubCategories />}
          /> */}
          {/* <Route path="/addEditCatalogue" element={<AddEditCatalogue />} /> */}
          <Route
            path="/addAddress"
            element={<Protected element={AddAddress} />}
          />
          <Route
            path="/storeProducts"
            element={<Protected element={StoreProducts} />}
          />
          <Route
            path="/storeLogin"
            element={<Protected element={StoreLogIn} />}
          />
          <Route
            path="/storePanelList"
            element={<Protected element={StorePanelList} />}
          />
          <Route path="/categories" element={<CategoriesPage />} />
          <Route path="/category/:id" element={<CategoryPage />} />
          <Route
            path="/dashboard"
            element={<Protected element={StoreDashboard} />}
          />
          <Route
            path="/featuredListing"
            element={<Protected element={FeaturedListings} />}
          />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/myItems" element={<Protected element={MyItems} />} />
          <Route
            path="/userProfile"
            element={<Protected element={UserProfile} />}
          />
          <Route
            path="/manageOffers"
            element={<Protected element={ManageOffers} />}
          />
          <Route
            path="/manageOrders"
            element={<Protected element={ManageOrders} />}
          />
          <Route
            path="/notifications"
            element={<Protected element={Notifications} />}
          />
          <Route
            path="/watizenCode"
            element={<Protected element={WatizenCode} />}
          />
          <Route
            path="/watizenDashboard"
            element={<Protected element={WatizenDashboard} />}
          />
          <Route
            path="/watizenPanel"
            element={<Protected element={WatizenPanel} />}
          />
          <Route
            path="/watizenList"
            element={<Protected element={WatizenList} />}
          />
          <Route
            path="/watizenSuccessful"
            element={<Protected element={WatizenSuccessful} />}
          />
          <Route path="/verify" element={<PIOnly element={VerifyOtp} />} />
          <Route path="/stores" element={<StoresPage />} />
          <Route
            path="/storePanel"
            element={<Protected element={StorePanel} />}
          />
          <Route
            path="/verifyAccount"
            element={<PIOnly element={VerifyAccount} />}
          />
          <Route path="/phoneVerification" element={<PhoneNumber />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/verifyOTP" element={<VerifyOTP />} />
          <Route path="/location" element={<Protected element={Location} />} />
          <Route path="/languageSetting" element={<LanguageSetting />} />
          <Route
            path="/piActivity"
            element={<Protected element={ItemsActivityPage} />}
          />
          <Route
            path="/settings"
            element={<Protected element={AppSettings} />}
          />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/feedback" element={<Protected element={Feedback} />} />
          <Route path="/items" element={<ItemsPage />} />
          <Route
            path="/post-product"
            element={<Protected element={PostItemPage} />}
          />
          <Route
            path="/edit-product/:id"
            element={<Protected element={PostItemPage} />}
          />
          <Route
            path="/myItems/wishlist"
            element={<Protected element={WishlistPage} />}
          />
          <Route
            path="/myItems/my-posted-items"
            element={<Protected element={EditItemsPage} />}
          />
          <Route
            path="/transaction-success"
            element={<Protected element={Transactionsuccess} />}
          />
          <Route path="/search" element={<SearchPage />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* <Route
            path="/myItems/items-activity"
            element={<ItemsActivityPage />}
          /> */}
          <Route path="/user/products/:userId" element={<UsersProductPage />} />
          {/*          <Route path="/settings/location" element={<Location />} /> */}
          <Route path="/chats" element={<Protected element={ChatsPage} />} />
          <Route
            path="/chats/chat/:chatId"
            element={<Protected element={ChatPage} />}
          />
          <Route
            path="/chats/product/:productId"
            element={<Protected element={ChatPage} />}
          />
          <Route path="/tokenLogin/:id/:encKey" element={<TokenLogin />} />
          <Route path="/piRedirect/:path" element={<PathRedirect />} />
          <Route
            path="/payment/:reason/:id"
            element={<PIOnly element={PaymentPage} />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
});

export default App;
