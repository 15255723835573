import ArrowLeftOutlined from "@ant-design/icons/lib/icons/ArrowLeftOutlined";
import { Button, Input, message } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { store } from "store/index.store";

import "./AddAddress.scss";
//import { FastForwardOutlined } from "@ant-design/icons";

// interface ObjeckType {
//   name: string;
//   age: number;
// }

export const AddAddress = observer(() => {
  const { UIStore } = store;

  const getLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition, showError);
    } else {
      message.error("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position: GeolocationPosition) => {
    const latitude = position.coords.latitude;
    const longitude = position.coords.longitude;
    const location =
      "latitude is " + latitude + " and longitude is " + longitude;
    // console.log("latitude" + latitude);
    // console.log("longitude" + longitude);
    UIStore.setLocation(location);

    // message.success('Latitude: ${latitude} Longitude: ${longitude}');
  };

  const showError = (error: GeolocationPositionError) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        message.error("User denied the request for Geolocation.");
        break;
      case error.POSITION_UNAVAILABLE:
        message.error("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        message.error("The request to get user location timed out.");
        break;
      default:
        message.error("An unknown error occurred.");
        break;
    }
  };

  const handleLocationChange = (e: any) => {
    UIStore.setLocation(e.target.value);
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <div>
      <div className="c-add-address__header-navigation">
        <ArrowLeftOutlined />
        <label style={{ margin: "20px", font: "20px", fontWeight: 500 }}>
          Add an address
        </label>
      </div>
      <div>
        <Input.TextArea
          id="address1"
          value={UIStore.location}
          onChange={handleLocationChange}
          autoSize={{ minRows: 1, maxRows: 5 }}
          style={{ width: "75%", height: "80px", margin: "50px" }}
        />
      </div>
      <div style={{ width: "100%" }}>
        <Link to={"/languageSetting"}>
          <Button
            type="primary"
            shape="round"
            className="c-add-address_save-button"
          >
            Save
          </Button>
        </Link>
      </div>
    </div>
  );
});
