import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { List } from "antd";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import "./ManageOffers.scss";


const data = [
    {title: 'Add offers',to:""},
    {title:'Current offers', to: ""},
    {title:'Verify offer ID', to:""},
    {title:'Availed offers', to:""}];

 export const ManageOffers = observer(() => {
    return(
        <>
            <div className="c-manage-offers_list" style={{padding:"10px"}}>
                <Link to={"/storePanelList"} style={{color:"black"}}>
                    <ArrowLeftOutlined />
                </Link>
                <label> Manage offers </label>
            </div>
            <List itemLayout="horizontal" dataSource={data} className="c-store-panel_list"
            renderItem={(item, index) => (
                <List.Item>
                    <List.Item.Meta title={<Link to={item.to}>{item.title}</Link>} />
                    <ArrowRightOutlined />
                </List.Item>
            )} />
        </>
    )
 });