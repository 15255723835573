import { ITransactionEntry } from "~/pages/ItemsActivityPage/ItemsActivityPage";
import { config, server } from "..";

export const getMySoldTransactions = async (
  skip: number,
  limit: number
): Promise<{ data: { payments: ITransactionEntry[] } }> => {
  return server.get(
    `${process.env.REACT_APP_API_URL}/payments/sold/${skip}/${limit}`,
    config
  );
};

export const getMyBoughtTransactions = async (
  skip: number,
  limit: number
): Promise<{ data: { payments: ITransactionEntry[] } }> => {
  return server.get(
    `${process.env.REACT_APP_API_URL}/payments/bought/${skip}/${limit}`,
    config
  );
};

export const getMyTransactions = async (
  skip: number,
  limit: number
): Promise<{ data: { payments: ITransactionEntry[] } }> => {
  return server.get(
    `${process.env.REACT_APP_API_URL}/payments/uid/${skip}/${limit}`,
    config
  );
};

export const reviewTransaction = async (txId: string, rating: number) => {
  return server.post(
    `${process.env.REACT_APP_API_URL}/payments/review/${txId}`,
    { rating },
    config
  );
};
