import { searchProducts } from "../../api/products";
import { PathBasedBreadcrumb } from "../../components/PathBasedBreadcrumb/PathBasedBreadcrumb";
import "./SearchPage.scss";
import { useState, useRef, useEffect } from "react";
import { IProduct } from "../../common/models";
import { productMapper } from "../../mappers/productMappers";
import { useLocation } from "react-router-dom";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import { Spin } from "antd";
import { Product } from "../../components/product/Product";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { store } from "../../store/index.store";

export const SearchPage = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);

  const { authStore } = store;

  const currentlyLoadedProducts = useRef(0);

  const location = useLocation();

  const querry = new URLSearchParams(location.search).get("querry") || "";
  const category = new URLSearchParams(location.search).get("category") || "";
  const condition =
    new URLSearchParams(location.search).get("condition") || undefined;

  const loadProducts = async () => {
    if (authStore.isLoggedIn && !authStore.location) return;
    try {
      setIsLoading(true);
      const productsReq = await searchProducts(
        currentlyLoadedProducts.current,
        currentlyLoadedProducts.current + 10,
        querry,
        category,
        condition,
        authStore.location?.map((loc) => loc.toString())
      );

      currentlyLoadedProducts.current =
        products?.length + productsReq?.productObj.length;

      if (productsReq?.productObj.length < 10)
        currentlyLoadedProducts.current = 0;

      setProducts((prods) => [
        ...prods,
        ...productsReq?.productObj.map(productMapper),
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setProducts([]);
    currentlyLoadedProducts.current = 0;
    loadProducts();
  }, [querry, category, condition, authStore.isLoggedIn, authStore.location]);

  useBottomScrollListener(
    () => {
      loadProducts();
    },
    {
      offset: 0,
      debounce: 200,
      debounceOptions: { leading: true },
      triggerOnNoScroll: false,
    }
  );

  function handleAddToCart(productID: string): void {}

  return (
    <div className="p-search">
      <div className="p-search__breadcrumb">
        <PathBasedBreadcrumb />
      </div>
      <div className="p-search__querry">Search results for: {querry}</div>
      <div className="p-search__items">
        <Spin spinning={isLoading}>
          <div className="p-search__items__container">
            {products.length > 0 ? (
              products.map((product, i) => (
                <Product
                  key={product.id + i}
                  mode="vertical"
                  onAddToCart={handleAddToCart}
                  {...product}
                />
              ))
            ) : (
              <div className="p-search__items__container-empty">
                <ShoppingCartOutlined className="p-search__items__container-empty-icon" />
                <div>No products found in your area</div>
              </div>
            )}
          </div>
        </Spin>
      </div>
    </div>
  );
});
