import { server, config } from "..";

export const sendOtp = ({ email }: { email: string }) => {
  return server.post("/get-otp", { email: email.toLowerCase() }, config);
};

export const changePassword = ({
  email,
  otp,
  password,
}: {
  email: string;
  otp: string;
  password: string;
}) => {
  return server.post(
    "/reset-password",
    {
      email: email.toLowerCase(),
      otp,
      password,
    },
    config
  );
};
