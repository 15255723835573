//@ts-ignore
import { toast } from "react-toastify";

//eslint-disable-next-line
enum LocationPermissionStatus {
  //eslint-disable-next-line
  GRANTED = "granted",
  //eslint-disable-next-line
  DENIED = "denied",
  //eslint-disable-next-line
  PROMPT = "prompt",
  //eslint-disable-next-line
  GEO_ERROR = "geoerror",
  //eslint-disable-next-line
  ERROR = "error",
}

const locationToasts = {
  [LocationPermissionStatus.GRANTED]: undefined,
  [LocationPermissionStatus.DENIED]: {
    type: "error" as const,
    text: "Location access denied. Please enable location access in your browser settings to be able to see products.",
  },
  [LocationPermissionStatus.PROMPT]: {
    type: "warning" as const,
    text: "Location access is required to see products near you. Please enable location access in your browser settings.",
  },
  [LocationPermissionStatus.GEO_ERROR]: {
    type: "error" as const,
    text: "Geolocation is not supported by your browser. Please enable location access in your browser settings to be able to see products.",
  },
  [LocationPermissionStatus.ERROR]: {
    type: "error" as const,
    text: "An error occured while trying to get your location. Please refresh the page.",
  },
};

const fireToast = (status: LocationPermissionStatus) => {
  const toastData = locationToasts[status];
  const autoClose = toastData?.type === "error" ? false : 5000;
  if (toastData && toastData.type) {
    toast(toastData.text, { type: toastData.type, autoClose });
  }
};

let locationWatchRef: null | number = null;

export const checkForLocationPermission = async () => {
  return new Promise((resolve, reject) => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      console.log("result", result);
      resolve(result.state);
    });
  });
};

const watchLocation = async (
  currentLocation: [number, number, number] | undefined,
  updateLocation: (location: [number, number, number]) => void
) => {
  console.log(navigator.geolocation);
  locationWatchRef = navigator.geolocation.watchPosition(
    (position) => {
      console.log("position", position);
      if (!!currentLocation) return;
      updateLocation([
        position.coords.latitude,
        position.coords.longitude,
        position.coords.accuracy,
      ]);

      if (locationWatchRef) navigator.geolocation.clearWatch(locationWatchRef);
    },
    (e) => {
      console.error("Error getting location", e);
      toast(
        "An error occured while trying to get your location. Please refresh the page. " +
          e.message,
        { type: "error", autoClose: false }
      );
    },
    { maximumAge: Infinity, enableHighAccuracy: false }
  );
};

export const initLocation = async (
  currentLocation: [number, number, number] | undefined,
  updateLocation: (location: [number, number, number]) => void
) => {
  if (!navigator.geolocation)
    return fireToast(LocationPermissionStatus.GEO_ERROR);

  //const permission = await checkForLocationPermission();
  const permission =
    LocationPermissionStatus.GRANTED as LocationPermissionStatus;

  switch (permission) {
    case LocationPermissionStatus.GRANTED:
      return watchLocation(currentLocation, updateLocation);
    case LocationPermissionStatus.DENIED:
      return fireToast(LocationPermissionStatus.DENIED);
    case LocationPermissionStatus.PROMPT:
      //          fireToast(LocationPermissionStatus.PROMPT);
      watchLocation(currentLocation, updateLocation);
      return;
    default:
      return fireToast(LocationPermissionStatus.GEO_ERROR);
  }
};

export const cancelLocation = () => {
  if (locationWatchRef)
    return navigator.geolocation.clearWatch(locationWatchRef);
};
