import { config, server } from "..";

export const updateProfile = (data: any) => {
  return server.patch("/profile", data, config);
};

export const getProfile = (token?: string) => {
  const Authorization = token
    ? `Bearer ${token}`
    : config?.headers?.Authorization;

  return server.get("/profile", {
    ...config,
    headers: {
      ...config.headers,
      Authorization,
    },
  });
};

export const updatePiProfile = (
  piApiKey: string,
  walletPublicAddress: string
) => {
  return server.post(
    "/setup-pi-data",
    { piApiKey, walletPublicAddress },
    config
  );
};

export const updateLocation = (data: { lat: number; long: number }) => {
  return server.patch("/users/location", data, config);
};

export const deleteAccount = () => {
  return server.delete("/profile", config);
};

export const updateWalletAdress = (wallet: string) => {
  return server.post("/set-public-wallet", { wallet }, config);
};
