import { ArrowLeftOutlined } from "@ant-design/icons"
import { Card, Col, Row } from "antd";
import { Link } from "react-router-dom";

import "./WatizenDashboard.scss";

export const WatizenDashboard = () => {
    return(
        <div className="c-watizen-dashboard">
            <div className="c-watizen-dashboard-header">
                <Link to={"/watizenList"}>
                    <ArrowLeftOutlined style={{color:"black"}}/>
                </Link>
                <label> Watizen / Dashboard </label>
            </div>
            <div className="c-watizen-dashboard-store">
                <Row className="c-watizen-dashboard-store-row">
                    <Col span={8} className="c-watizen-dashboard-store-row-col">
                        <Card className="c-watizen-dashboard-store-row-col-card">
                            <label className="c-watizen-dashboard-store-row-col-number">42</label>
                            <Row className="c-watizen-dashboard-store-row-col-label">
                                <label >Stores Added</label>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8} offset={8}>
                        <Card className="c-watizen-dashboard-store-row-col-card">
                            <label className="c-watizen-dashboard-store-row-col-number">28</label>
                            <Row className="c-watizen-dashboard-store-row-col-label">
                                <label >Active Stores</label>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Row className="c-watizen-dashboard-store-info">
                    <Card className="c-watizen-dashboard-store-info-row">
                        <label className="c-watizen-dashboard-store-row-col-number">150</label>
                        <Row className="c-watizen-dashboard-store-info-row-label">
                            <label >Eco-Friendly items Placed in your store</label>
                        </Row>
                    </Card>
                </Row>
                <Row className="c-watizen-dashboard-store-row">
                    <Col span={8} className="c-watizen-dashboard-store-row-col">
                        <Card className="c-watizen-dashboard-store-row-col-card">
                            <label className="c-watizen-dashboard-store-row-col-number">93k</label>
                            <Row className="c-watizen-dashboard-store-row-col-label">
                                <label >Earned In total</label>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={8} offset={8}>
                        <Card className="c-watizen-dashboard-store-row-col-card">
                            <label className="c-watizen-dashboard-store-row-col-number">1.5k</label>
                            <Row className="c-watizen-dashboard-store-row-col-label">
                                <label >Earned This month</label>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}