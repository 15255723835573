import { observer } from "mobx-react-lite";
import { store } from "../../store/index.store";

interface ChatListProps {
  messages: any[];
  loadMoreMessages: () => void;
}

export const ChatList = observer(
  ({ messages, loadMoreMessages }: ChatListProps) => {
    const { authStore } = store;

    //get the scrol position
    const scrollListener = (event: any) => {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (-scrollTop + clientHeight >= scrollHeight) {
        loadMoreMessages();
      }
    };

    return (
      <div className="c-messaging__messages" onScroll={scrollListener}>
        {messages.map((message) => (
          <div
            key={message._id + message.date.toString()}
            className={`c-messaging__messages-message c-messaging__messages-message--${
              message.senderId === authStore.userProfile.userId
                ? "mine"
                : "other"
            }
        
        ${
          message.unvalidated
            ? "c-messaging__messages-message--unvalidated"
            : "c-messaging__messages-message--validated"
        }
        `}
          >
            {message.message}
          </div>
        ))}
      </div>
    );
  }
);
